import ModalWrapper from './ModalWrapper.jsx'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useAtom, useAtomValue } from 'jotai'
import { planAtom } from '../../store/plan.store.js'
import { generateHtml, generatePdf } from '../../firestore.js'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import UpgradeMessage from '../UpgradeMessage.jsx'
import Button from '../Button.jsx'
import { EXPORT } from '../../constants/billingTypes.js'
import { columnsAtom } from '../../store/rundown.store.js'
import _without from 'lodash/without'

export default function RundownExportModal({
  rundownId,
  open,
  setOpen,
}) {
  const [loading, setLoading] = useState(false)
  const [columns] = useAtom(columnsAtom)
  const [columnsToExport, setColumnsToExport] = useState([])
  const [pdfPreview, setPdfPreview] = useState(null)
  const [orientation, setOrientation] = useState('landscape')
  const [textSize, setTextSize] = useState('6')
  const [alternateRowColors, setAlternateRowColors] = useState(false)
  const [showElapsedTimes, setShowElapsedTimes] = useState(false)
  const plan = useAtomValue(planAtom)

  useEffect(() => {
    setColumnsToExport(columns.slice(0, 4).map((c) => c.id))
  }, [columns])

  async function handleDownloadPdf () {
    setLoading(true)

    try {
      const { data } = await generatePdf(rundownId, orientation, { columnsToExport, textSize, alternateRowColors, showElapsedTimes })
      const link = document.createElement('a')
      link.href = import.meta.env.VITE_RUNDOWN_FUNCTION_BASE + data.url
      link.target = '_blank' // open in a new tab
      link.download = data.filename // use the filename from the response
      link.click()
    } catch (error) {
      console.error('Error downloading the PDF:', error)
    } finally {
      setLoading(false)
    }
  }

  function handleToggleAllColumns () {
    if (columnsToExport.length === 0) return setColumnsToExport(columns.map((c) => c.id))
    setColumnsToExport([])
  }

  function handleCheckUnCheck (event) {
    const updatedArray = event.target.checked ? [...columnsToExport, event.target.id] : _without(columnsToExport, event.target.id)
    return setColumnsToExport(updatedArray)
  }

  useMemo(() => {
    if (!open) return
    setPdfPreview(null)
    const fetchHtmlContent = async () => {
      const response = await generateHtml(rundownId, { columnsToExport, textSize, alternateRowColors, showElapsedTimes })
      setPdfPreview(response.data)
    }
    fetchHtmlContent()
  }, [open, rundownId, JSON.stringify(columnsToExport), textSize, alternateRowColors, showElapsedTimes])

  return (
    <ModalWrapper
      width={1000}
      title="Export PDF"
      open={[open, setOpen]}
    >
      <div className="space-y-6 text-gray-400 font-light">
        <p>Export a copy of your rundown in PDF format.</p>

        <div className="grid grid-cols-3 gap-6">
          <div>
            <div className="mb-4">
              <p className="mb-1">Orientation</p>
              <select
                onChange={(e) => setOrientation(e.target.value)}
                value={orientation}
                className="px-3 h-9 w-full bg-gray-800 rounded-sm flex gap-2 cursor-pointer hover:brightness-110 focus:outline-hidden focus:ring-3"
              >
                <option value="landscape">Landscape</option>
                <option value="portrait">Portrait</option>
              </select>
            </div>
            <div className="mb-4">
              <p className="mb-1">Alternating colors</p>
              <label
                htmlFor="alternateRowColorsInput"
                className={[
                  'p-2 bg-gray-800 rounded-sm flex gap-2',
                  'cursor-pointer hover:brightness-110',
                ].join(' ')}
              >
                <input
                  id="alternateRowColorsInput"
                  type="checkbox"
                  className="focus:outline-hidden focus:ring-3"
                  checked={alternateRowColors}
                  onChange={(e) => setAlternateRowColors(e.target.checked)}
                />
                <span>Alternate row background color</span>
              </label>
            </div>
            <div className="mb-4">
              <p className="mb-1">Text size</p>
              <select
                onChange={(e) => setTextSize(e.target.value)}
                value={textSize}
                className="px-3 h-9 w-full bg-gray-800 rounded-sm flex gap-2 cursor-pointer hover:brightness-110 focus:outline-hidden focus:ring-3"
              >
                <option value="6">Small</option>
                <option value="7">Medium</option>
                <option value="8">Large</option>
              </select>
            </div>
            <div className="mb-4">
              <p className="mb-1">Elapsed times</p>
              <label
                htmlFor="elapsedTimes"
                className={[
                  'p-2 bg-gray-800 rounded-sm flex gap-2',
                  'cursor-pointer hover:brightness-110',
                ].join(' ')}
              >
                <input
                  id="elapsedTimes"
                  type="checkbox"
                  className="focus:outline-hidden focus:ring-3"
                  checked={showElapsedTimes}
                  onChange={(e) => setShowElapsedTimes(e.target.checked)}
                />
                <span>Include elapsed times on PDF</span>
              </label>
            </div>
            <div className="space-y-2">
              <p className="text-gray-400 font-light">Choose which columns to include</p>
              <div className={[
                'flex flex-col gap-2 justify-center',
              ].join(' ')}
              >
                {columns.map((column, i) => (
                  <label
                    key={i}
                    htmlFor={`${column?.id}`}
                    className={[
                      'p-2 bg-gray-800 rounded-sm flex gap-2',
                      'cursor-pointer hover:brightness-110',
                    ].join(' ')}
                  >
                    <input
                      id={`${column?.id}`}
                      type="checkbox"
                      className="focus:outline-hidden focus:ring-3"
                      checked={columnsToExport?.includes(column?.id)}
                      onChange={handleCheckUnCheck}
                    />
                    <span>{column?.name || 'Unnamed Column'}</span>
                  </label>
                ))}
              </div>
              <button
                className="text-xs text-gray-400 focus:outline-hidden focus:ring-3 disabled:opacity-50"
                onClick={handleToggleAllColumns}
              >
                Toggle all
              </button>
            </div>
          </div>
          <div className="col-span-2">
            <p className="mb-1">Preview</p>
            <div className="bg-gray-800 rounded-sm shadow-inner overflow-hidden">
              <div className={[
                'bg-white p-1',
                orientation === 'landscape' ? 'aspect-297/210 h-[443.09px]' : 'aspect-210/297  h-[443.09px] mx-auto',
              ].join(' ')}
              >
                {
                  pdfPreview
                    ? (
                        <iframe
                          srcDoc={pdfPreview}
                          className={[
                            'w-full pointer-events-none',
                            orientation === 'landscape' ? 'aspect-297/210' : 'aspect-210/297',
                          ].join(' ')}
                          style={orientation === 'landscape'
                            ? { width: '166.666%', transform: 'scale(0.6)', transformOrigin: 'top left' }
                            : { width: '222.222%', transform: 'scale(0.45)', transformOrigin: 'top left' }}
                        >
                        </iframe>
                      )
                    : (
                        <div className="h-full flex items-center justify-center">
                          <p>Loading...</p>
                        </div>
                      )
                }
              </div>
            </div>
            <p className="text-xs text-gray-400 mt-2">Preview may differ from the final PDF. Please check the final document.</p>
          </div>
        </div>

        <div className="flex justify-end">
          <Button
            text="Download PDF"
            icon={faDownload}
            loading={loading}
            onClick={handleDownloadPdf}
            disabled={!plan.features.includes(EXPORT)}
          />
        </div>
        {!plan.features.includes(EXPORT) && (
          <UpgradeMessage message="Upgrade your account to export the rundown." className="text-right" />
        )}
      </div>
    </ModalWrapper>
  )
}

RundownExportModal.propTypes = {
  rundownId: PropTypes.string.isRequired,
  rundownName: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}
