import { formatTimeOfDay, formatTimezone } from '@rundown-studio/timeutils'
import { CueStartMode, RunnerState } from '@rundown-studio/types'
import { CueRunState } from '@rundown-studio/utils'
import { differenceInCalendarDays } from 'date-fns'
import { useMemo } from 'react'
import { tz } from '@date-fns/tz'
import singularPlural from '../../../utils/singularPlural'

const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
  weekday: 'short',
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
})

/**
 * Hook that provides formatted timing descriptions for cues
 */
export function useTimingDescription ({
  timestamp,
  startTime,
  startMode = CueStartMode.FLEXIBLE,
  scheduled = false,
  timezone = 'UTC',
  runnerState = RunnerState.PRESHOW,
  todDisplayFormat = null,
  rundownStartTime,
}) {
  return useMemo(() => {
    // Early return if no valid timing info
    if (!timestamp && !startTime) return ''

    // Get basic formatting elements used by both states
    const tzAbbr = formatTimezone(timezone, 'abbr')

    if (runnerState === RunnerState.PRESHOW) {
      return formatPreshowTiming({
        startTime,
        startMode,
        scheduled,
        timezone,
        todDisplayFormat,
        tzAbbr,
        rundownStartTime,
      })
    }

    return formatRuntimeTiming({
      timestamp,
      scheduled,
      timezone,
      todDisplayFormat,
      tzAbbr,
    })
  }, [
    timestamp,
    startTime,
    startMode,
    scheduled,
    timezone,
    runnerState,
    todDisplayFormat,
    rundownStartTime,
  ])
}

/**
 * Formats timing description during the planning phase (PRESHOW)
 */
function formatPreshowTiming ({
  startTime,
  startMode,
  scheduled,
  timezone,
  todDisplayFormat,
  tzAbbr,
  rundownStartTime,
}) {
  const timeStr = formatTimeOfDay(startTime, {
    timezone,
    seconds: 'nonzero',
    format: todDisplayFormat,
  })

  const dateStr = dateFormatter.format(startTime)
  const modeStr = startMode === CueStartMode.FIXED ? 'hard' : 'soft'
  const startStr = scheduled ? 'auto-start' : 'start'

  // Days offset display
  const daysPlus = differenceInCalendarDays(startTime, rundownStartTime, { in: tz(timezone) })
  const daysOffsetStr = daysPlus > 0 ? '+' + singularPlural(daysPlus, 'day', 'days') : ''

  // In planning mode, include date for hard cues only
  const parens = [tzAbbr, daysOffsetStr]
  if (startMode === CueStartMode.FIXED) {
    parens.unshift(dateStr)
  }

  return `Planned ${modeStr} ${startStr} at ${timeStr} (${_arrJoin(parens)}).`.replaceAll('  ', ' ')
}

/**
 * Formats timing description during show runtime (ONAIR) and after show (ENDED)
 */
function formatRuntimeTiming ({
  timestamp,
  scheduled,
  timezone,
  todDisplayFormat,
  tzAbbr,
}) {
  const timeStr = formatTimeOfDay(timestamp.actual.start, {
    timezone,
    seconds: 'nonzero',
    format: todDisplayFormat,
  })

  // Days offset display (if any)
  const daysOffsetStr = timestamp.actual.daysPlus > 0
    ? (`+${timestamp.actual.daysPlus} ${timestamp.actual.daysPlus === 1 ? 'day' : 'days'}`)
    : ''

  // Timing state prefix
  const prefix = timestamp.state === CueRunState.CUE_PAST || timestamp.state === CueRunState.CUE_ACTIVE
    ? 'Started'
    : 'Expected to'

  const startStr = scheduled ? 'auto-start' : 'start'

  // Calculate time difference from planned schedule
  let diffStr = ''
  if (timestamp.original.start) {
    const diffMs = timestamp.actual.start.getTime() - timestamp.original.start.getTime()
    if (diffMs === 0) {
      diffStr = 'right on time'
    } else {
      const diffMinutes = Math.abs(Math.round(diffMs / 60000))
      const diffHours = Math.floor(diffMinutes / 60)
      const remainingMinutes = diffMinutes % 60

      let diffTimeStr = ''
      if (diffHours > 0) {
        diffTimeStr += `${diffHours} ${diffHours === 1 ? 'hour' : 'hours'}`
        if (remainingMinutes > 0) {
          diffTimeStr += ` ${remainingMinutes} ${remainingMinutes === 1 ? 'minute' : 'minutes'}`
        }
      } else {
        diffTimeStr = `${diffMinutes} ${diffMinutes === 1 ? 'minute' : 'minutes'}`
      }

      diffStr = diffMs > 0
        ? `${diffTimeStr} later than planned`
        : `${diffTimeStr} earlier than planned`
    }
  }

  // Compose final string
  const parens = [tzAbbr, daysOffsetStr]
  return `${prefix} ${startStr} at ${timeStr} (${_arrJoin(parens)})${diffStr ? ', ' + diffStr : ''}.`.replaceAll('  ', ' ')
}

/**
 * Joins array elements with commas and replaces spaces with non-breaking spaces
 * within each element to prevent unwanted line breaks
 */
function _arrJoin (arr) {
  return arr
    .filter(Boolean)
    .map((str) => str.replace(/ /g, '\u00A0'))
    .join(', ')
}
