import PropTypes from 'prop-types'
import TimeInput from '../TimeInput.jsx'
import { CueStartMode, RunnerState } from '@rundown-studio/types'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../interactives/Tooltip.jsx'
import AdvancedSelect from '../../interactives/AdvancedSelect.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { addDays } from '@rundown-studio/timeutils'
import { nanoid } from 'nanoid/non-secure'
import { useCallback } from 'react'
import { useTimingDescription } from './useTimingDescription.js'

export default function StartTimePopoverContent ({
  startTime,
  startDatePlus,
  startMode,
  scheduled,
  setStartTime,
  setStartDatePlus,
  setStartMode,
  // setScheduled,
  timezone,
  timestamp,
  disabled = false,
  isFirstCue = false,
  timingProps,
}) {
  const _id = nanoid()
  const isSoftCue = startMode === CueStartMode.FLEXIBLE

  const formattedText = useTimingDescription({
    timestamp,
    startTime,
    startMode,
    scheduled,
    timezone,
    runnerState: timingProps.runnerState,
    todDisplayFormat: timingProps.todDisplayFormat,
    rundownStartTime: timingProps.rundownStartTime,
  })

  const getDateDetail = useCallback((num) => {
    const dateFormatter = new Intl.DateTimeFormat('en-US', { weekday: 'short', day: 'numeric', month: 'long' })
    return dateFormatter.format(addDays(timingProps.rundownStartTime, num, { timezone }))
  }, [timingProps, timezone])

  return (
    <div className="w-64 space-y-2">
      <h3 className="font-semibold text-sm">
        Cue Start
      </h3>
      <div className="flex w-full bg-gray-900 rounded">
        <Tooltip>
          <TooltipContent>
            {isFirstCue
              ? 'The first cue is always a hard start'
              : 'This cues start time is flexible and will be calculated based on the previous cues end time'}
          </TooltipContent>
          <TooltipTrigger className="w-1/2">
            <button
              disabled={disabled || isFirstCue}
              onClick={() => setStartMode(CueStartMode.FLEXIBLE)}
              className={[
                'relative text-sm h-8 rounded-l w-full disabled:pointer-events-none',
                (startMode === CueStartMode.FLEXIBLE ? 'bg-blue-500 font-semibold z-10' : 'hover:bg-gray-800'),
                ((disabled || isFirstCue) ? 'opacity-50' : ''),
              ].join(' ')}
            >
              {startMode === CueStartMode.FLEXIBLE && <FontAwesomeIcon icon={faCheck} className="mr-1" />}
              Soft start
            </button>
          </TooltipTrigger>
        </Tooltip>
        <Tooltip>
          <TooltipContent>
            {isFirstCue
              ? 'The first cue is always a hard start'
              : 'A hard start is when you plan to start this cue manually at a specified time'}
          </TooltipContent>
          <TooltipTrigger className="w-1/2">
            <button
              disabled={disabled || isFirstCue}
              onClick={() => setStartMode(CueStartMode.FIXED)}
              className={[
                'relative text-sm h-8 rounded-r w-full disabled:pointer-events-none',
                (startMode === CueStartMode.FIXED ? 'bg-blue-500 font-semibold z-10' : 'hover:bg-gray-800'),
                (disabled ? 'opacity-50' : ''),
              ].join(' ')}
            >
              {startMode === CueStartMode.FIXED && <FontAwesomeIcon icon={faCheck} className="mr-1" />}
              Hard start
            </button>
          </TooltipTrigger>
        </Tooltip>
      </div>
      <div className="flex">
        <label htmlFor={`${_id}-startTime`} className="w-12 text-sm flex-none leading-7 text-gray-200">
          Time:
        </label>
        <TimeInput
          id={`${_id}-startTime`}
          className="w-full! h-7! px-2! rounded-sm! text-sm bg-gray-800! hover:enabled:brightness-125"
          disabled={disabled || isSoftCue}
          timezone={timezone}
          time={startTime}
          updateTime={setStartTime}
          refDate={startTime}
        />
      </div>
      <Tooltip placement="right">
        <TooltipTrigger>
          <div className="flex">
            <label htmlFor={`${_id}-startDatePlus`} className="w-12 text-sm flex-none leading-7 text-gray-200">
              Date:
            </label>
            <div className="w-full">
              <AdvancedSelect
                id={`${_id}-startDatePlus`}
                className="w-full! h-7! px-2! rounded-sm! text-sm bg-gray-800! hover:enabled:brightness-125"
                disabled={isFirstCue || disabled || isSoftCue}
                options={[
                  { value: '0', label: '+0 days', detail: getDateDetail(0) },
                  { value: '1', label: '+1 day', detail: getDateDetail(1) },
                  { value: '2', label: '+2 days', detail: getDateDetail(2) },
                  { value: '3', label: '+3 days', detail: getDateDetail(3) },
                ]}
                value={startDatePlus.toString()}
                setValue={(newValue) => setStartDatePlus(Number(newValue))}
              />
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          Select the day for this cue in multi-day shows relative to the rundown date.
        </TooltipContent>
      </Tooltip>
      {/* <Tooltip placement="right">
        <TooltipTrigger>
          <div className="flex">
            <div className="w-12" />
            <label htmlFor={`${_id}-scheduled`} className="text-sm text-gray-200">
              <input
                id={`${_id}-scheduled`}
                type="checkbox"
                disabled={disabled}
                checked={scheduled}
                onChange={() => setScheduled(!scheduled)}
                className="text-xs focus:outline-none focus:ring mr-1.5"
              />
              {isSoftCue ? 'Auto-advance' : 'Auto-start'}
            </label>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          {isSoftCue
            ? `Cue auto-starts after the previous cue`
            : `Cue auto-starts at the scheduled time`}
        </TooltipContent>
      </Tooltip> */}
      {formattedText && <p className="text-xs text-white/80">{formattedText}</p>}
    </div>
  )
}

StartTimePopoverContent.propTypes = {
  startTime: PropTypes.instanceOf(Date),
  startDatePlus: PropTypes.number,
  startMode: PropTypes.oneOf(Object.values(CueStartMode)),
  scheduled: PropTypes.bool,
  setStartTime: PropTypes.func.isRequired,
  setStartDatePlus: PropTypes.func.isRequired,
  setStartMode: PropTypes.func.isRequired,
  setScheduled: PropTypes.func.isRequired,
  timezone: PropTypes.string,
  timestamp: PropTypes.object,
  disabled: PropTypes.bool,
  isFirstCue: PropTypes.bool,
  timingProps: PropTypes.shape({
    runnerState: PropTypes.oneOf(Object.values(RunnerState)),
    todDisplayFormat: PropTypes.string,
    rundownStartTime: PropTypes.instanceOf(Date),
  }),
}
