import { format } from 'date-fns'
import ModalWrapper from './ModalWrapper.jsx'
import PropTypes from 'prop-types'
import { cancelSubscription, updateAirtable } from '../../firestore.js'
import { useState } from 'react'
import { useAtom, useSetAtom } from 'jotai'
import { toastAtom } from '../../store/toast.store.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { planAtom } from '../../store/plan.store.js'

export default function CancelSubscriptionModal ({ open, setOpen, plan, teamId }) {
  const [loading, setLoading] = useState(false)
  const [cancelled, setCancelled] = useState(false)
  const [reason, setReason] = useState('')
  const [storePlan, setStorePlan] = useAtom(planAtom)

  const addToast = useSetAtom(toastAtom)

  const handleCancelSubscription = async () => {
    setLoading(true)
    try {
      const { data } = await cancelSubscription(teamId, plan.subId)
      setStorePlan({ ...storePlan, ...data })
      addToast({ title: 'Subscription successfully cancelled', type: 'success' })
      setCancelled(true)
    } catch {
      addToast({ title: 'There was an issue canceling this subscription', body: 'Please try again or reach out to support', type: 'fail' })
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitReason = async () => {
    setLoading(true)
    if (!reason) return null
    try {
      await updateAirtable({ payload: { 'Cancellation Reason': reason } })
      addToast({ title: 'Thanks for your feedback', type: 'success' })
    } catch {
      addToast({ title: 'We were not able to save your feedback', type: 'fail' })
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  return (
    <ModalWrapper
      title="Confirm your cancellation"
      open={[open, setOpen]}
      buttons={[{
        text: 'Close',
        colour: 'dark',
        onClick: () => setOpen(false),
      },
      cancelled
        ? {
            text: 'Submit',
            colour: 'main',
            disabled: !reason,
            onClick: () => handleSubmitReason(),
          }
        : {
            text: 'Cancel subscription',
            colour: 'main',
            onClick: () => handleCancelSubscription(),
          },
      ]}
      working={loading}
    >
      <div className="py-2 flex flex-col gap-2">
        {
          cancelled
            ? (
                <>
                  <p className="px-2 py-0.5 w-full bg-green-950 rounded-sm ">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                    {' '}
                    Subscription cancellation confirmed
                  </p>
                  <span className="border-b-2 border-gray-700 my-2 inline-block"></span>
                  <p className="font-semibold">Quick question: Why are you cancelling today?</p>
                  <p className="text-sm text-gray-400">Your feedback will help us improve Rundown Studio</p>
                  <textarea
                    className="p-2 text-sm bg-gray-800 rounded-sm"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    rows={3}
                    placeholder="Type your answer..."
                  />
                </>
              )
            : (
                <>
                  <p className="text-sm text-balance">Once confirmed, you will no longer be billed and your subscription will be scheduled for cancellation on the next payment date.</p>
                  {plan.billingCycle?.endsAt && (
                    <p className="text-sm text-gray-400">
                      Subscription cancellation date: {format(new Date(plan.billingCycle.endsAt), 'd MMM, yyyy')}
                    </p>
                  )}
                </>
              )
        }
      </div>
    </ModalWrapper>
  )
}

CancelSubscriptionModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  plan: PropTypes.object.isRequired,
  teamId: PropTypes.string.isRequired,
}
