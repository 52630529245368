import { useRef } from 'react'
import { fastDeepEqual } from '@rundown-studio/utils'

/**
 * useDeepCompareMemo
 *
 * Custom React hook that memoizes a value with deep comparison of dependencies and results.
 * This hook is particularly useful for complex objects that change frequently but often
 * result in deeply equivalent values.
 *
 * Features:
 * - Preflight check: Only computes new value if dependencies have changed (deep equality)
 * - Postflight check: Only updates reference if the result is different from previous (deep equality)
 * - Maintains stable references when computed values are deeply equal
 *
 * @param {Function} factory - The factory function that computes the memoized value
 * @param {Array} dependencies - The dependency array to trigger recalculation
 * @returns {any} The memoized value
 *
 * @example
 * // Instead of:
 * const value = useMemo(() => computeExpensiveValue(a, b), [a, b]);
 *
 * // Use:
 * const value = useDeepCompareMemo(() => computeExpensiveValue(a, b), [a, b]);
 */
export function useDeepCompareMemo (factory, dependencies) {
  const ref = useRef({
    deps: dependencies,
    result: undefined,
    initialized: false,
  })

  // Compute new result only if dependencies changed (preflight)
  let newResult = ref.current.result
  if (!ref.current.initialized || !fastDeepEqual(dependencies, ref.current.deps)) {
    // Dependencies changed, compute new result
    newResult = factory()

    // Update only if result is different (postflight) or first initialization
    if (!ref.current.initialized || !fastDeepEqual(newResult, ref.current.result)) {
      ref.current.result = newResult
    }

    // Always update deps and mark as initialized
    ref.current.deps = dependencies
    ref.current.initialized = true
  }

  return ref.current.result
}

export default useDeepCompareMemo
