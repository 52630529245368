import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

export default function LightboxModal({ src, handleClose }) {
  const downloadUrl = `${import.meta.env.VITE_AUX_FUNCTION_BASE}/download?url=${src}`

  return createPortal(
    <div
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-xs flex items-center justify-center z-1000"
      onClick={handleClose}
      onKeyDown={(e) => (e.key === 'Escape' ? handleClose() : null)}
    >
      <div
        className="p-2 relative rounded-lg bg-gray-900/80 max-w-[90vw]"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute -top-10 right-0 z-10 w-10 h-10 rounded-sm"
          onClick={handleClose}
          autoFocus
        >
          <FontAwesomeIcon icon={faClose} size="xl" />
        </button>
        <img src={src} className="block max-w-full rounded-lg max-h-[80vh]!" />
        <div className="flex justify-center mt-2">
          <a
            href={downloadUrl}
            target="_blank"
            rel="noreferrer"
            className="text-sm underline text-gray-300! focus:outline-hidden focus:ring-3 rounded-sm"
          >
            Download
          </a>
        </div>
      </div>
    </div>,
    document.body,
  )
}

LightboxModal.propTypes = {
  src: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
}
