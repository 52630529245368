import { RunnerState } from '@rundown-studio/types'
import { atom } from 'jotai'

//
// Runner
//
const _runnerAtom = atom(null)
export const runnerAtom = atom(
  (get) => {
    const runner = get(_runnerAtom)

    // If new runner or null, return actual runner
    if (!runner || runner.originalCues) return runner

    return null
  },
  (get, set, runner) => {
    set(_runnerAtom, runner)
  },
)

export const runnerStateAtom = atom(
  (get) => {
    const runner = get(runnerAtom)
    if (!runner) return RunnerState.PRESHOW
    if (!runner.timesnap.cueId) return RunnerState.ENDED
    return RunnerState.ONAIR
  },
  null,
)

//
// Time Sync
//
export const timeOffsetAtom = atom(0) // Difference between server and client time

// Getter atom that provides server-adjusted current time
export const getNowAtom = atom((get) => {
  const offset = get(timeOffsetAtom)
  return () => Date.now() + offset
})

//
// Ping
//
export const pingAtom = atom(0)
