import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faArrowsLeftRight, faEllipsis, faEyeSlash, faPlus, faTableColumns, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import EditableInput from '../../EditableInput.jsx'
import { addRundownColumn, deleteRundownColumn, updateRundownColumn } from '../../../firestore.js'
import { useParams, useSearchParams } from 'react-router-dom'
import { ACCESS_WRITE, ACCESS_WRITE_COLUMN } from '../../../constants/rundownAccessStates.js'
import { RundownToken } from '../../../axios.js'
import _without from 'lodash/without'
import _clamp from 'lodash/clamp'
import { useSetAtom } from 'jotai'
import { setColumnWidthAtom } from '../../../store/rundown.store'
import { DEFAULT_COLUMN_WIDTH } from '../../../constants/columnWidth.js'
import PropTypes from 'prop-types'
import { Menu, MenuItem, MenuSeparator } from '../../interactives/DropdownMenu.jsx'
import { dragStartInterceptor } from '../../../utils/dragStartInterceptor.js'

export default function ColumnHeader ({
  column = {},
  hiddenColumns = [],
  moveColumn,
  updateWidth,
  onDragStart = () => {},
  onDragEnd = () => {},
  onDragOver = () => {},
  isDragging,
}) {
  const { rundownId } = useParams()
  const setColumnWidth = useSetAtom(setColumnWidthAtom)
  const [touched, setTouched] = useState(false)
  const [dividerHover, setDividerHover] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const hidden = hiddenColumns.includes(column.id)
  const canEdit = RundownToken.access === ACCESS_WRITE
  const canEditColumn = RundownToken.access === ACCESS_WRITE_COLUMN

  const editableColumns = searchParams?.get('edit-columns')?.split(',') || []

  const updateSearchParams = () => {
    // const searchParams = new URLSearchParams(window.location.search)
    let colIds = (searchParams.get('hiddenColumns') || '').split(',').filter(Boolean)
    if (colIds.includes(column.id)) colIds = _without(colIds, column.id)
    else colIds.push(column.id)
    searchParams.set('hiddenColumns', colIds)
    setSearchParams(searchParams)
  }

  const [dividerDragging, setDividerDragging] = useState(false)
  const [dividerPos, setDividerPos] = useState(column.width || DEFAULT_COLUMN_WIDTH)

  const containerElement = useRef()
  const memoizedOnDividerMove = useCallback(onDividerMove, [dividerDragging])
  const memoizedOnDividerUp = useCallback(onDividerUp, [dividerDragging])

  function onDividerDown (event) {
    event.preventDefault()
    setDividerDragging(true)
  }

  function onDividerMove (event) {
    event.preventDefault()
    if (!dividerDragging) return
    setTouched(true)
    const bounding = containerElement?.current.getBoundingClientRect()
    const newPos = (event.clientX) - bounding.left
    const MIN_POS = 100 // never smaller than 100px
    const MAX_POS = 800 // never bigger than 800px
    setDividerPos(_clamp(newPos, MIN_POS, MAX_POS))
    // setColumnWidth(column.id, _clamp(newPos, MIN_POS, MAX_POS))
  }

  function onDividerUp (event) {
    event.preventDefault()
    setDividerDragging(false)
    setTouched(false)
    setColumnWidth(column.id, containerElement?.current.getBoundingClientRect().width)
    updateWidth(column.id, containerElement?.current.getBoundingClientRect().width)
  }

  useEffect(() => {
    if (dividerDragging) {
      document.addEventListener('mousemove', memoizedOnDividerMove)
      document.addEventListener('mouseup', memoizedOnDividerUp)
    }
    return () => {
      document.removeEventListener('mousemove', memoizedOnDividerMove)
      document.removeEventListener('mouseup', memoizedOnDividerUp)
    }
  }, [dividerDragging])

  useEffect(() => {
    if (!touched) setDividerPos(column.width)
  }, [column.width])

  if (hidden) return null
  return (
    <div
      ref={containerElement}
      draggable={canEdit}
      onDragStart={dragStartInterceptor.bind(null, onDragStart.bind(null, column.id))}
      onDragEnd={onDragEnd}
      onDragOver={(e) => onDragOver(e, column.id)}
      style={{
        width: `${dividerPos}px`,
      }}
      className="group relative"
    >
      {/* Header container */}
      <div className="relative flex items-center justify-between border-b-2 border-white/20">

        {/* Left side - Column name input */}
        <EditableInput
          className="flex-1 h-6 text-sm min-w-0 text-white truncate text-ellipsis"
          value={column.name}
          placeholder="Column name"
          onChange={(name) => updateRundownColumn(rundownId, column.id, { name })}
          disabled={!canEdit}
        />

        {/* Right side - Buttons */}
        <div className="flex items-center gap-1 flex-none group-hover:backdrop-blur-md rounded-md absolute top-0 right-0">
          {canEdit && (
            <Menu
              icon={faEllipsis}
              className="!h-6 !w-6 !p-1 opacity-0 group-hover:opacity-30 hover:opacity-100 transition-opacity"
            >
              <MenuItem
                label="Hide column for me"
                onClick={updateSearchParams}
                icon={faEyeSlash}
              />
              <Menu
                label="Column type..."
                icon={faTableColumns}
              >
                <MenuItem
                  label="Richtext"
                  onClick={() => updateRundownColumn(rundownId, column.id, { type: 'richtext' })}
                  active={column.type === 'richtext'}
                />
                <MenuItem
                  label="Dropdown"
                  onClick={() => updateRundownColumn(rundownId, column.id, { type: 'select' })}
                  active={column.type === 'select'}
                />
              </Menu>
              <MenuItem
                label="Add new column"
                onClick={() => addRundownColumn(rundownId, { after: column.id })}
                icon={faPlus}
              />
              <MenuItem
                label="Move left"
                onClick={() => moveColumn(column.id, 'left')}
                icon={faArrowLeft}
              />
              <MenuItem
                label="Move right"
                onClick={() => moveColumn(column.id, 'right')}
                icon={faArrowRight}
              />
              <MenuSeparator />
              <MenuItem
                label="Delete column"
                onClick={() => deleteRundownColumn(rundownId, column.id)}
                icon={faTrashAlt}
              />
            </Menu>
          )}

          {canEditColumn && editableColumns?.includes(column?.id) && (
            <div className="px-1 py-0.5 bg-green-900 text-green-100 rounded-xs text-xs my-auto">
              Editable
            </div>
          )}

          {canEdit && (
            <div data-draghandle className="h-6 w-4 opacity-0 group-hover:opacity-30 hover:opacity-100 px-1 cursor-grab transition-opacity">
              <FontAwesomeIcon icon={faArrowsLeftRight} />
            </div>
          )}

          {canEdit && (
            <>
              <div className="w-3" />
              <div
                onMouseDown={onDividerDown}
                onMouseEnter={() => setDividerHover(true)}
                onMouseLeave={() => setDividerHover(false)}
                className="absolute -right-2 h-6 w-4 opacity-0 group-hover:opacity-30 hover:opacity-100 px-1 cursor-col-resize transition-opacity"
              />
            </>
          )}
        </div>
      </div>

      {(dividerDragging || dividerHover) && !isDragging && (
        <span className="absolute -right-1 bg-gray-500 w-0.5 h-screen inline-block"></span>
      )}
    </div>
  )
}

ColumnHeader.propTypes = {
  column: PropTypes.object,
  hiddenColumns: PropTypes.array,
  moveColumn: PropTypes.func,
  updateWidth: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragOver: PropTypes.func,
  isDragging: PropTypes.bool,
}
