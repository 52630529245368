import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ToggleButton ({ options, selected, updateSelected }) {
  return (
    <div className="grid grid-cols-2 rounded-xs p-1 text-gray-00 bg-gray-800 text-center my-2 select-none border border-gray-700">
      {options.map((option, index) => (
        <button
          key={index}
          type="button"
          disabled={option.disabled}
          className={[
            'rounded-xs transition-colors py-1',
            selected === option.value ? 'bg-gray-300 text-gray-800' : 'hover:text-gray-300',
          ].join(' ')}
          onClick={() => updateSelected(option.value)}
        >
          <div className="flex flex-col items-center pt-1">
            <FontAwesomeIcon icon={option.icon} size="xl" fixedWidth />
            <p>{option.label}</p>
          </div>
        </button>
      ))}
    </div>
  )
}

ToggleButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  selected: PropTypes.string.isRequired,
  updateSelected: PropTypes.func.isRequired,
}
