import PropTypes from 'prop-types'
import SavedStateIndicator from '../../reusables/SavedStateIndicator'

export default function CueTimingSchaffold ({
  className = '',
  style,
  sup,
  top,
  bottom,
  sub,
  loading,
  children,
}) {
  return (
    <div
      className={[
        className,
        'relative w-28 flex-none flex flex-col justify-stretch items-center text-center gap-0.5',
      ].join(' ')}
      style={style}
    >
      <div className="flex-1 w-full relative">
        <div className="absolute h-5 top-0 w-full">{sup}</div>
        <div className="absolute h-4 bottom-0 left-1/2 -translate-x-1/2 max-w-full">{top}</div>
      </div>
      <div className="flex-none h-6">{children}</div>
      <div className="flex-1 w-full relative">
        <div className="absolute h-4 top-0 left-1/2 -translate-x-1/2 max-w-full">{bottom}</div>
        <div className="absolute h-5 bottom-0 w-full">{sub}</div>
      </div>
      <SavedStateIndicator
        className="absolute z-30 bottom-1 right-1"
        saving={loading}
      />
    </div>
  )
}

CueTimingSchaffold.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  sup: PropTypes.any,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  sub: PropTypes.any,
  loading: PropTypes.bool,
  children: PropTypes.any,
}
