import { Popover, PopoverContent, PopoverTrigger } from '../../interactives/Popover'
import TimeInput from '../TimeInput'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { updateRundown } from '../../../firestore'
import formatTimeOfDay from '../../../utils/formatTimeOfDay'

/**
 * PlannedEnd component
 *
 * This component displays and manages the planned end time of a rundown.
 * It uses a popover for editing the end time, allowing the user to input
 * a new time via the TimeInput component. Updates to the end time are
 * saved to the database through the `updateRundown` function.
 *
 * Props:
 * - rundown (object): The rundown data, including its `id`, `endTime` and `timezone`.
 *
 * Features:
 * - Displays the formatted end time.
 * - Provides a popover to edit the end time.
 * - Saves the updated end time on outside clicks or Enter key press.
 * - Shows a loading animation while saving the changes.
 */
export default function PlannedEnd ({ rundown }) {
  const [internalEndTime, setInternalEndTime] = useState(rundown.endTime)
  const [loading, setLoading] = useState(false)

  async function onSaveEndTime () {
    if (internalEndTime.getTime() === rundown.endTime.getTime()) return
    setLoading(true)
    await updateRundown(rundown.id, { endTime: internalEndTime })
    setLoading(false)
  }

  return (
    <section>
      <div className="pl-1.5 text-gray-500 font-semibold whitespace-pre text-[10px]">
        Planned end
      </div>
      <Popover
        onOutsideClick={onSaveEndTime}
        closeWithEnter={true}
        onEnterKey={onSaveEndTime}
      >
        <PopoverTrigger asChild={true}>
          <button className={['px-1.5 py-0.5 text-sm hover:bg-gray-800 rounded-sm', loading ? 'bg-animated from-gray-800/50 to-gray-600/50' : ''].join(' ')}>
            {formatTimeOfDay(rundown.endTime, { timezone: rundown.timezone, seconds: 'nonzero', format: rundown.settings?.todDisplayFormat })}
          </button>
        </PopoverTrigger>
        <PopoverContent onSave={onSaveEndTime}>
          <div className="flex pl-1">
            <label htmlFor="startTime" className="w-16 text-sm leading-7 text-gray-200">Time: </label>
            <TimeInput
              id="startTime"
              className="w-full! h-7! px-2! rounded-sm! text-sm bg-gray-800! hover:enabled:brightness-125"
              timezone={rundown.timezone}
              time={internalEndTime}
              updateTime={setInternalEndTime}
            />
          </div>
        </PopoverContent>
      </Popover>
    </section>
  )
}

PlannedEnd.propTypes = {
  rundown: PropTypes.object.isRequired,
}
