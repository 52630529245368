import { faArrowRightLong, faCog, faCopy, faPlus, faTrashAlt, faGripLines, faPalette, faForwardStep, faUnlock, faLock, faArrowUp, faArrowDown, faLeftRight } from '@fortawesome/free-solid-svg-icons'
import { CueType } from '@rundown-studio/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, memo } from 'react'
import PropTypes from 'prop-types'
import ModalWrapper from '../modal/ModalWrapper.jsx'
import { Tooltip, TooltipContent, TooltipTrigger } from '../interactives/Tooltip.jsx'
import { filteredAndSortedCuesAtom, isCueSelectedAtom, rundownAtom, selectedCueIdsAtom, toggleSelectedCuesAtom } from '../../store/rundown.store.js'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { Menu, MenuItem, MenuSeparator } from '../interactives/DropdownMenu.jsx'
import CueColorPicker from './CueColorPicker.jsx'

function CueItemSettings ({
  cue,
  readonly,
  onUpdateCue,
  onDeleteCue,
  onAddCue,
  onDuplicateCue,
  onJumpToCue,
  onSetNext,
  parentId,
  setRundownColoursModalOpen,
  cueBackgroundColours,
}) {
  const [deletingGroup, setDeletingGroup] = useState(false)
  const filteredAndSortedCues = useAtomValue(filteredAndSortedCuesAtom)
  const rundown = useAtomValue(rundownAtom)
  const getIsCueSelected = useAtomValue(isCueSelectedAtom)
  const [selectedCues, setSelectedCues] = useAtom(selectedCueIdsAtom)
  const toggleSelectedCue = useSetAtom(toggleSelectedCuesAtom)
  const isCueSelected = getIsCueSelected(cue.id)
  const isParentCueSelected = getIsCueSelected(parentId)

  /**
   * Handles the selection of cues in the rundown.
   *
   * - If the shift key is held during selection, selects a range of cues from the
   *   last selected cue to the current one. If any group cues are selected in this
   *   range, their child cues are removed from the selection.
   * - If selecting a group cue directly, removes any selected children cues of that
   *   group and selects the group cue itself.
   * - Otherwise, toggles the selected state of the individual cue.
   *
   * @param {Object} event - The selection event.
   */
  function onSelectCue(event) {
    // Check if shift key is held to perform range selection
    if (event.nativeEvent.shiftKey) {
      const sortedCueIds = filteredAndSortedCues.map((c) => c.id)
      const lastSelectedIndex = sortedCueIds.indexOf(selectedCues[selectedCues.length - 1])
      const currentIndex = sortedCueIds.indexOf(cue.id)

      // Determine the start and end indices for the range selection
      const [start, end] = [lastSelectedIndex, currentIndex].sort((a, b) => a - b)
      const newSelectedIds = sortedCueIds.slice(start, end + 1)

      // Combine current selections with the new range, removing duplicates
      const updatedSelectedCues = Array.from(new Set([...selectedCues, ...newSelectedIds]))

      // If any group cues are selected, remove their child cues
      const groupHeaders = rundown.cues.filter((c) => c.children && updatedSelectedCues.includes(c.id))
      const selectedChildren = groupHeaders.flatMap((g) => g.children.map((c) => c.id))
      const selectedWithoutChildren = updatedSelectedCues.filter((id) => !selectedChildren.includes(id))

      setSelectedCues(selectedWithoutChildren)
    } else {
      // Handle individual cue selection or group cue selection
      if (event.target.checked && cue.type === CueType.GROUP) {
        // Remove any selected children cues of this group and select the group cue
        const childrenCues = rundown.cues.find((c) => c.id === cue.id).children?.map((c) => c.id)
        const selectedWithoutChildren = selectedCues.filter((id) => !childrenCues.includes(id))
        setSelectedCues([...selectedWithoutChildren, cue.id])
      } else {
        // Toggle the selected state of the individual cue
        toggleSelectedCue(cue.id)
      }
    }
  }

  if (readonly) return null
  return (
    <div className={[
      'flex flex-col h-full group-hover:backdrop-blur-sm min-h-14 justify-between rounded-sm',
      isParentCueSelected || isCueSelected ? 'bg-blue-700/90' : '',
    ].join(' ')}
    >
      {/* Drag Handle */}
      {cue.locked || selectedCues.length > 0
        ? <span className="h-6"></span>
        : (
            <button
              className="flex-none w-full opacity-0 group-hover:opacity-70 hover:opacity-100! cursor-grab! rounded-sm focus:outline-hidden focus:ring-3"
              data-draghandle
            >
              <FontAwesomeIcon icon={faGripLines} />
            </button>
          )}
      {/* Gear Button */}
      <Menu
        icon={faCog}
        disabled={selectedCues.length > 0 || isParentCueSelected}
        className={[
          'bg-transparent! h-7! text-base! justify-center! w-full rounded-sm',
          'group-hover:enabled:opacity-70 enabled:hover:opacity-100! transition-opacity focus:outline-hidden focus:ring-3 disabled:hidden',
          parentId ? 'group-hover:opacity-30 opacity-0' : 'opacity-30',
          isCueSelected ? 'opacity-30' : '',
        ].join(' ')}
      >
        <div className="mb-1">
          <CueColorPicker onClick={(color) => onUpdateCue({ backgroundColor: color })} rundownColors={cueBackgroundColours} />
        </div>
        <MenuItem icon={faPalette} label="Edit rundown colors" onClick={setRundownColoursModalOpen.bind(null, true)} />
        <MenuSeparator />
        <MenuItem icon={faForwardStep} label="Jump to this cue" onClick={onJumpToCue} disabled={[CueType.HEADING, CueType.GROUP].includes(cue.type)} />
        <MenuItem icon={faArrowRightLong} label="Cue up next" onClick={onSetNext} disabled={[CueType.HEADING, CueType.GROUP].includes(cue.type)} />
        <MenuItem icon={faCopy} label={`Duplicate ${cue.type === CueType.GROUP ? 'group' : 'cue'}`} onClick={onDuplicateCue} />
        <Menu icon={faPlus} label="Add cue...">
          <MenuItem icon={faArrowUp} label="Add cue above" onClick={onAddCue.bind(null, { position: 'before' })} />
          <MenuItem icon={faArrowDown} label="Add cue below" onClick={onAddCue.bind(null, { position: 'after' })} />
        </Menu>
        <Menu icon={faLeftRight} label="Convert to..." disabled={cue.type === CueType.GROUP}>
          <MenuItem label="Cue" active={cue.type === CueType.CUE} disabled={cue.type === CueType.CUE} onClick={onUpdateCue.bind(null, { type: CueType.CUE })} />
          <MenuItem label="Heading" active={cue.type === CueType.HEADING} disabled={cue.type === CueType.HEADING} onClick={onUpdateCue.bind(null, { type: CueType.HEADING })} />
        </Menu>
        {
          cue.locked
            ? <MenuItem icon={faUnlock} label="Allow edits" onClick={onUpdateCue.bind(null, { locked: false })} />
            : <MenuItem icon={faLock} label="Prevent edits" onClick={onUpdateCue.bind(null, { locked: true })} />
        }
        <MenuSeparator />
        {
          cue.type === CueType.GROUP
            ? <MenuItem icon={faTrashAlt} label="Delete group" onClick={setDeletingGroup.bind(null, true)} />
            : <MenuItem icon={faTrashAlt} label="Delete cue" onClick={onDeleteCue} />
        }
      </Menu>
      {/* Lock Button OR Collapse button */}
      {
        cue.locked
          ? (
              <Tooltip>
                <TooltipTrigger asChild={true}>
                  <button
                    className="flex-none w-full h-7 rounded-sm group-hover:opacity-70 hover:opacity-100! transition-opacity backdrop-blur-sm text-xs focus:outline-hidden focus:ring-3 group/lock opacity-70 text-yellow-500"
                    onClick={onUpdateCue.bind(null, { locked: false })}
                  >
                    <span className="hidden group-hover/lock:inline">
                      <FontAwesomeIcon icon={faUnlock} size="sm" />
                    </span>
                    <span className="group-hover/lock:hidden">
                      <FontAwesomeIcon icon={faLock} size="sm" />
                    </span>
                  </button>
                </TooltipTrigger>
                <TooltipContent>Allow cue editing</TooltipContent>
              </Tooltip>
            )
          : !isParentCueSelected && (
              <Tooltip>
                <TooltipTrigger>
                  <div
                    className="flex flex-col justify-center align-middle w-full h-7 rounded-sm"
                  >
                    <label htmlFor={`select-${cue.id}`} className="mx-auto p-1.5">
                      <input
                        type="checkbox"
                        checked={isCueSelected}
                        onChange={onSelectCue}
                        id={`select-${cue.id}`}
                        className={[
                          'mx-auto group-hover:opacity-70 hover:opacity-100! transition-opacity text-xs focus:outline-hidden focus:ring-3',
                          isCueSelected ? 'opacity-70' : 'opacity-0',
                        ].join(' ')}
                      />
                    </label>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  {isCueSelected ? 'Deselect' : 'Select'}
                  {' '}
                  {cue.type === CueType.GROUP ? 'group' : 'cue'}
                </TooltipContent>
              </Tooltip>
            )
      }
      {deletingGroup
      && (
        <ModalWrapper
          open={[deletingGroup, setDeletingGroup]}
          title="Delete Group"
          buttons={[
            {
              text: 'Cancel',
              onClick: () => setDeletingGroup(false),
            },
            {
              text: 'Delete Group',
              icon: faTrashAlt,
              onClick: async () => {
                setDeletingGroup(false)
                onDeleteCue()
              },
            },
          ]}
        >
          <p>Deleting this group will permanently remove all cues within it.</p>
        </ModalWrapper>
      )}
    </div>
  )
}

CueItemSettings.propTypes = {
  cue: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
  onUpdateCue: PropTypes.func.isRequired,
  onDeleteCue: PropTypes.func.isRequired,
  onAddCue: PropTypes.func.isRequired,
  onDuplicateCue: PropTypes.func.isRequired,
  onJumpToCue: PropTypes.func.isRequired,
  onSetNext: PropTypes.func.isRequired,
  parentId: PropTypes.string,
  setRundownColoursModalOpen: PropTypes.func,
  cueBackgroundColours: PropTypes.array,
}

export default memo(CueItemSettings, (prevProps, nextProps) => {
  // Note: We don't need to compare stable callbacks
  // 1: Comparing array signature should be enough
  const propsEqual
    = prevProps.cue.id === nextProps.cue.id
      && prevProps.cue.type === nextProps.cue.type
      && prevProps.cue.locked === nextProps.cue.locked
      && prevProps.readonly === nextProps.readonly
      && prevProps.parentId === nextProps.parentId
      && prevProps.cueBackgroundColours === nextProps.cueBackgroundColours /* 1 */

  return propsEqual
})
