import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

export default function EditableInput ({
  className,
  value = '',
  onChange,
  placeholder = '',
  disabled = false,
}) {
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [text, setText] = useState(value || '')

  const inputRef = useRef()

  async function onConfirm () {
    setEditing(false)
    if (text === value) return
    setLoading(true)
    try {
      await onChange(text)
    } catch {
      /* ignore */
    }
    setLoading(false)
    inputRef.current?.blur()
  }

  function onCancel() {
    setEditing(false)
    inputRef.current?.setSelectionRange(0, 0)
    inputRef.current?.blur()
    setText(value)
  }

  function onKeydown (event) {
    if (event.key === 'Enter') onConfirm()
    if (event.key === 'Escape') onCancel()
  }

  function onOutsideClick (event) {
    if (inputRef.current?.contains(event.target)) return
    onConfirm()
  }

  useEffect(() => {
    document.addEventListener('mousedown', onOutsideClick)
    return () => document.removeEventListener('mousedown', onOutsideClick)
  })

  useEffect(() => {
    if (editing) {
      inputRef.current?.select()
    }
  }, [editing])

  useEffect(() => {
    setText(value)
  }, [value])

  if (disabled) {
    return (
      <p className={['text-left', className].join(' ')}>
        {text}
      </p>
    )
  }

  if (editing) {
    return (
      <input
        ref={inputRef}
        className={[
          'w-full bg-transparent focus:outline-hidden focus:ring-3 rounded-sm text-left',
          className,
        ].join(' ')}
        value={text}
        placeholder={placeholder}
        onChange={(e) => setText(e.target.value)}
        onBlur={onConfirm}
        onKeyDown={onKeydown}
      />
    )
  }

  return (
    <button
      className={[
        'bg-transparent text-left cursor-text decoration-dashed decoration-white/50 rounded-sm break-words',
        (loading ? 'bg-animated from-transparent to-white/20 pointer-events-none' : ''),
        className,
      ].join(' ')}
      onClick={() => setEditing(true)}
      onFocus={() => setEditing(true)}
    >
      {text
        ? (
            <span>{text}</span>
          )
        : (
            <span className="opacity-0 group-hover:opacity-40 transition-opacity">{placeholder}</span>
          )}
    </button>
  )
}

EditableInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}
