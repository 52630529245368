import { faAdd, faBolt, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import ColumnHeader from './ColumnHeader.jsx'
import _indexOf from 'lodash/indexOf'
import { addRundownColumn, reorderRundownColumns, updateRundownColumn } from '../../../firestore.js'
import { useAtom, useSetAtom } from 'jotai'
import { getColumnById, setColumnOrderAtom } from '../../../store/rundown.store.js'
import _without from 'lodash/without'
import { RundownToken } from '../../../axios.js'
import { ACCESS_WRITE } from '../../../constants/rundownAccessStates.js'
import Button from '../../Button.jsx'
import UpgradeModal from '../../modal/UpgradeModal.jsx'
import PropTypes from 'prop-types'
import { Menu, MenuItem, MenuSeparator } from '../../interactives/DropdownMenu.jsx'

export default function ColumnHeaders ({ columns = [], hiddenColumns = [], columnsOrder = [], plan }) {
  const { rundownId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [columnById] = useAtom(getColumnById)
  const setColumnOrder = useSetAtom(setColumnOrderAtom)
  const [loading, setLoading] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const [draggedColumn, setDraggedColumn] = useState(null)
  const [draggedToPosition, setDraggedToPosition] = useState(null)

  const updateSearchParams = async (columnId) => {
    const hiddenColsArray = searchParams.get('hiddenColumns').split(',')
    switch (columnId) {
      case 'show_all':
        searchParams.delete('hiddenColumns')
        return setSearchParams(searchParams)

      default:
        searchParams.set('hiddenColumns', _without(hiddenColsArray, columnId).join(','))
        return setSearchParams(searchParams)
    }
  }

  const columnLimitExceeded = plan.limits.columns !== -1 && plan.limits.columns <= columns.length
  const handleAddNewColumn = async () => {
    if (columnLimitExceeded) return setShowUpgradeModal(true)

    setLoading(true)
    await addRundownColumn(rundownId)
    return setLoading(false)
  }

  /**
   * Moves a column left or right by determining its new index and calling `handleReorderColumnsByIndex`.
   * This function is used for directional movement rather than absolute positioning.
   */
  const handleReorderColumnsByDirection = (columnId, direction) => {
    const oldIndex = _indexOf(columnsOrder, columnId)

    if (oldIndex === -1) return

    // Adjust `moveTo` for left and right movement
    const moveTo = direction === 'right' ? oldIndex + 2 : oldIndex - 1

    handleReorderColumnsByIndex(columnId, moveTo)
  }

  /**
   * Moves a column to an exact index in the order array.
   * Used for precise reordering, such as drag-and-drop column positioning.
   */
  const handleReorderColumnsByIndex = (columnId, moveTo) => {
    const oldIndex = _indexOf(columnsOrder, columnId)

    // If the column is already in the correct position, do nothing
    if (oldIndex === -1 || moveTo === -1) return

    // Adjust moveTo to convert from position-between-columns to actual index
    const newIndex = moveTo > oldIndex ? moveTo - 1 : moveTo

    // Remove the column from its old position
    const columnsArray = [...columnsOrder]
    const [movedColumn] = columnsArray.splice(oldIndex, 1)
    columnsArray.splice(newIndex, 0, movedColumn)

    // Optimistic update, then send to the server
    setColumnOrder(columnsArray)
    return reorderRundownColumns(rundownId, columnsArray)
  }

  const handleUpdateWidth = (columnId, width) => {
    return updateRundownColumn(rundownId, columnId, { width })
  }

  function onDragStart (columnId) {
    setDraggedColumn(columnId)
  }

  function onDragEnd () {
    setDraggedColumn(null)
    setDraggedToPosition(null)
  }

  function onDragOver(event, columnId) {
    if (!draggedColumn) return

    event.preventDefault() // Prevent default drag behavior

    const targetElement = event.currentTarget
    const elementWidth = targetElement.offsetWidth
    const mouseX = event.clientX
    const elementMidpoint = targetElement.getBoundingClientRect().left + elementWidth / 2

    const targetIndex = _indexOf(columnsOrder, columnId)
    let newIndex = mouseX > elementMidpoint ? targetIndex + 1 : targetIndex

    // Update the state to track the new drop index
    if (newIndex !== draggedToPosition) {
      setDraggedToPosition(newIndex)
    }
  }

  function onDrop() {
    if (draggedColumn !== null && draggedToPosition !== null) {
      handleReorderColumnsByIndex(draggedColumn, draggedToPosition)
    }
    setDraggedColumn(null)
    setDraggedToPosition(null)
  }

  return (

    <div className="relative flex gap-1">
      <div className="relative flex gap-1" onDrop={onDrop}>
        {columns.map((column, index) => {
          return (
            <div key={index} className="relative">
              {/* On reorder, render the vertical bar if it's before this column */}
              {draggedColumn !== null && draggedToPosition === index && (
                <div className="absolute h-full w-1 bg-blue-500/80 -left-1 rounded-full" />
              )}

              <ColumnHeader
                key={`${column?.id}_${_indexOf(columnsOrder, column?.id)}`}
                column={column}
                hiddenColumns={hiddenColumns}
                moveColumn={handleReorderColumnsByDirection}
                updateWidth={handleUpdateWidth}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
                isDragging={draggedColumn !== null}
              />
            </div>
          )
        })}

        {/* On reorder, render the vertical bar at the end if dragging beyond the last column */}
        {draggedColumn !== null && draggedToPosition === columns.length && (
          <div className="absolute h-full w-1 bg-blue-500/80 right-0 rounded-full" />
        )}
      </div>

      {RundownToken.access === ACCESS_WRITE
      && (
        <Button
          icon={faAdd}
          colour="dark"
          text="Add column"
          size="sm"
          className="px-2"
          onClick={handleAddNewColumn}
          loading={loading}
          toolTip={columnLimitExceeded
            ? (
                <>
                  Limit reached
                  <FontAwesomeIcon icon={faBolt} className="text-yellow-500" />
                  {' '}
                  Upgrade for more
                </>
              )
            : ''}
        />
      )}

      <UpgradeModal
        open={showUpgradeModal}
        setOpen={setShowUpgradeModal}
        onHide={() => setShowUpgradeModal(false)}
        message="You&apos;ve reached your limit for columns on this rundown."
      />

      {(hiddenColumns.length > 0 && RundownToken.access === ACCESS_WRITE) && (
        <div className="relative px-2">
          <Menu icon={faEye} className="h-7!">
            <MenuItem disabled={true} label="Show column" />
            <MenuSeparator />
            {
              hiddenColumns.map((c) => {
                return <MenuItem key={c} label={columnById(c)?.name} onClick={updateSearchParams.bind(null, c)} />
              })
            }
            <MenuSeparator />
            <MenuItem label="Show all" onClick={updateSearchParams.bind(null, 'show_all')} />
          </Menu>
        </div>
      )}
    </div>
  )
}

ColumnHeaders.propTypes = {
  columns: PropTypes.array,
  hiddenColumns: PropTypes.array,
  columnsOrder: PropTypes.array,
  plan: PropTypes.object,
}
