import * as React from 'react'
import PropTypes from 'prop-types'
import mitt from 'mitt'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  FloatingFocusManager,
} from '@floating-ui/react'
import Button from '../Button'

// Event types for our popover communication
const EVENTS = {
  ESCAPE_KEY: 'escape-key',
  OUTSIDE_CLICK: 'outside-click',
  FOCUS_OUT: 'focus-out',
}

export function usePopover({
  initialOpen = false,
  placement = 'bottom',
  modal,
  open: controlledOpen,
  onOpen = () => {},
  onClose = () => {},
  onOutsideClick = () => {},
  onOpenChange: setControlledOpen,
}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)
  const [labelId, setLabelId] = React.useState()
  const [descriptionId, setDescriptionId] = React.useState()

  // Create an event emitter instance
  const emitter = React.useMemo(() => mitt(), [])

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const setOpenWithMiddleware = (open, event, reason) => {
    if (event) event.preventDefault()
    if (!open) {
      if (reason === 'escape-key') emitter.emit(EVENTS.ESCAPE_KEY)
      if (reason === 'focus-out') emitter.emit(EVENTS.FOCUS_OUT)
      if (reason === 'outside-press') {
        onOutsideClick()
        emitter.emit(EVENTS.OUTSIDE_CLICK)
      }
    }
    setOpen(open)
  }

  React.useEffect(() => {
    if (open) onOpen()
    else onClose()
  }, [open])

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpenWithMiddleware,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'end',
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  })

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  return React.useMemo(
    () => ({
      open,
      setOpen: setOpenWithMiddleware,
      emitter,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, interactions, data, modal, labelId, descriptionId, emitter],
  )
}

const PopoverContext = React.createContext(null)

export const usePopoverContext = () => {
  const context = React.useContext(PopoverContext)

  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />')
  }

  return context
}

export function Popover({
  children,
  modal = false,
  ...restOptions
}) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, ...restOptions })
  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  )
}

Popover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  modal: PropTypes.bool,
}

export const PopoverTrigger = React.forwardRef(function PopoverTrigger({ children, asChild = false, ...props }, propRef) {
  const context = usePopoverContext()
  const childrenRef = children.ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      }),
    )
  }

  return (
    <button
      ref={ref}
      type="button"
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  )
})

PopoverTrigger.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  asChild: PropTypes.bool,
}

/**
 * PopoverContent component supports abort control in onSave/onCancel callbacks.
 *
 * @example
 * <PopoverContent
 *   onSave={({ abort }) => {
 *     if (!isValid) {
 *       abort() // Prevents popover from closing
 *       return
 *     }
 *     // Popover will close after this
 *   }}
 * >
 *   ...content
 * </PopoverContent>
 */
export const PopoverContent = React.forwardRef(function PopoverContent({ children, style, className, onSave, hideButtons = false, saveDisabled = false, onCancel, ...props }, propRef) {
  const { context: floatingContext, ...context } = usePopoverContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  const [loading, setLoading] = React.useState(false)

  const handle = async (func) => {
    if (!func) return context.setOpen(false)

    let aborted = false
    const abortController = {
      abort: () => (aborted = true),
    }

    setLoading(true)
    try {
      const result = func(abortController)
      if (result instanceof Promise) {
        await result
      }
      if (!aborted) {
        context.setOpen(false)
      }
    } catch (error) {
      console.error('Error during handling:', error)
    } finally {
      setLoading(false)
    }
  }

  // Set up event listener for escape key
  React.useEffect(() => {
    const handleEscape = () => {
      if (onCancel && !loading) handle(onCancel)
    }

    const handleFocusOut = () => {
      if (loading) return
      if (onSave && !saveDisabled) handle(onSave)
      else if (onCancel) handle(onCancel)
    }

    context.emitter.on(EVENTS.ESCAPE_KEY, handleEscape)
    context.emitter.on(EVENTS.OUTSIDE_CLICK, handleFocusOut)
    context.emitter.on(EVENTS.FOCUS_OUT, handleFocusOut)
    return () => {
      context.emitter.off(EVENTS.ESCAPE_KEY, handleEscape)
      context.emitter.off(EVENTS.OUTSIDE_CLICK, handleFocusOut)
      context.emitter.off(EVENTS.FOCUS_OUT, handleFocusOut)
    }
  }, [onCancel, onSave, saveDisabled, loading])

  /**
   * Save on enter.
   *
   * Note: Using keyUp to avoid conflict with browser default behavior on keyDown
   *
   * Only handle Enter if:
   * 1. The key is Enter
   * 2. It hasn't been prevented by another handler
   * 3. We have an onSave callback
   * 4. Save isn't disabled
   * 5. We're not already loading
   * 6. The target is not a textarea (to allow multiline input)
   */
  const handleKeyUp = (event) => {
    if (
      event.key === 'Enter'
      && !event.isDefaultPrevented()
      && onSave
      && !saveDisabled
      && !loading
      && event.target.tagName.toLowerCase() !== 'textarea'
    ) {
      event.preventDefault()
      handle(onSave)
    }
  }

  if (!floatingContext.open) return null

  return (
    <FloatingPortal>
      <FloatingFocusManager context={floatingContext} modal={context.modal}>
        <div
          ref={ref}
          style={{ ...context.floatingStyles, ...style }}
          className={['z-100 rounded-lg border border-white/10 bg-black/80 p-2.5 shadow-lg backdrop-blur-lg', className].join(' ')}
          aria-labelledby={context.labelId}
          aria-describedby={context.descriptionId}
          onKeyUp={handleKeyUp}
          {...context.getFloatingProps(props)}
        >
          {children}
          {!hideButtons && (
            <div className="flex justify-end space-x-2.5 mt-2.5">
              <Button className="grow px-2" size="sm" text="Cancel" colour="dark" loading={loading} onClick={() => handle(onCancel)} />
              <Button className="grow px-2" size="sm" text="Save" colour="dark" loading={loading} disabled={saveDisabled} onClick={() => handle(onSave)} />
            </div>
          )}
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  )
})

PopoverContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  style: PropTypes.object,
  className: PropTypes.string,
  onSave: PropTypes.func,
  saveDisabled: PropTypes.bool,
  hideButtons: PropTypes.bool,
  onCancel: PropTypes.func,
}
