import ModalWrapper from './ModalWrapper.jsx'
import { ProgressBarCss } from '../toast/ToastWrapper.jsx'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createCode } from '../../firestore.js'

export default function QuickAccessCodeModal ({ open, setOpen, url = null }) {
  const [accessCode, setAccessCode] = useState(null)

  async function generateCode () {
    const { data } = await createCode(url)
    setAccessCode(data.id)
  }

  return (
    <ModalWrapper
      title="Quick access code"
      open={[open, setOpen]}
      buttons={[{
        text: 'Close',
        colour: 'dark',
        onClick: () => setOpen(false),
      }]}
      onShow={generateCode}
      onHide={() => setAccessCode(null)}
    >
      {!accessCode
        ? (
            <div className="px-6 w-[300px] mx-auto p-3 space-y-2 border border-gray-700 rounded-sm text-center bg-animated from-gray-800 to-gray-500"></div>
          )
        : (
            <div className="pb-10">
              <div className="relative px-6 w-[300px] mx-auto p-3 space-y-2 border border-gray-700 rounded-sm text-center">
                <div
                  style={{
                    opacity: 0,
                    animationName: 'fadeOut',
                    animationDuration: '60s',
                    animationTimingFunction: 'linear',
                  }}
                  className="relative inline-block font-mono font-semibold text-2xl space-x-1 bg-gray-700 rounded-sm px-2 z-10"
                >
                  <span>{accessCode.substring(0, 3)}</span>
                  <span>{accessCode.substring(3, 6)}</span>
                </div>
                <span className="pt-2 absolute inset-0 text-sm text-center text-gray-500 pointer-events-none">Expired</span>

                <p className="text-xs text-gray-500">This code is valid for 60 seconds</p>
                <div className="relative pt-3 pb-2">
                  <ProgressBarCss duration={60000} />
                </div>
                <p className="text-xs text-gray-500">
                  Use it at
                  {' '}
                  <a href={`${window?.location.origin}/code`} className="underline" rel="noreferrer" target="_blank">
                    {`${window?.location.origin}/code`}
                  </a>
                </p>
              </div>
            </div>
          )}
    </ModalWrapper>
  )
}

QuickAccessCodeModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  url: PropTypes.string,
}
