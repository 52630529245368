import Layout from '../../components/Layout.jsx'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const STATUS = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  SERVER_ERROR: 500,
}

export default function DashboardError ({ error = new Error('Dashboard not found') }) {
  const status = Object.values(STATUS).includes(error?.response?.status) ? error?.response?.status : STATUS.SERVER_ERROR

  function getErrorTitle (status) {
    return {
      [STATUS.UNAUTHORIZED]: 'Access Denied',
      [STATUS.NOT_FOUND]: 'Not Found',
      [STATUS.FORBIDDEN]: 'Access Restricted',
      [STATUS.SERVER_ERROR]: 'Server Error',
    }[status] || 'Error'
  }

  function getErrorMessage (status) {
    return {
      [STATUS.UNAUTHORIZED]: 'You need to be logged in to view this dashboard.',
      [STATUS.NOT_FOUND]: 'We couldn\'t find the dashboard you\'re looking for.',
      [STATUS.FORBIDDEN]: 'You don\'t have access to this dashboard.',
      [STATUS.SERVER_ERROR]: 'Something went wrong on our end.',
    }[status] || error?.response?.data || error?.message
  }

  return (
    <Layout title={getErrorTitle(status)}>
      <div className="space-y-6">
        <p className="font-medium">
          {getErrorMessage(status)}
        </p>

        {status === STATUS.UNAUTHORIZED && (
          <div className="text-gray-500 space-y-2">
            <p>
              Please log in to access your team dashboards.
            </p>
            <Link
              to={`/login?next=${window.location.pathname}`}
              className="underline"
            >
              Go to Login →
            </Link>
          </div>
        )}
        {status === STATUS.NOT_FOUND && (
          <p className="text-gray-500">
            The dashboard may have been deleted or the URL might be incorrect.
          </p>
        )}
        {status === STATUS.FORBIDDEN && (
          <p className="text-gray-500">
            You need to be a member of the team that owns this dashboard to view it.
          </p>
        )}
        {status === STATUS.SERVER_ERROR && (
          <div className="text-gray-500 space-y-2">
            <p>
              Don&apos;t worry - this is probably an issue on our end, not something you did.
            </p>
            <p>
              If this keeps happening, please contact us at
              {' '}
              <a
                href="mailto:hello@rundownstudio.app"
                className="underline hover:text-gray-400"
              >
                hello@rundownstudio.app
              </a>
            </p>
          </div>
        )}

        <hr className="border-gray-800" />

        {/* We include the actual error message here for when people send us screenshots */}
        <div className="text-sm text-gray-700 text-center">
          &quot;[{error?.response?.status}] {error?.response?.data || error?.message}&quot;
        </div>
      </div>
    </Layout>
  )
}

DashboardError.propTypes = {
  error: PropTypes.instanceOf(Error),
}
