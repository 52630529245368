import PropTypes from 'prop-types'
import { useMemo } from 'react'
import singularPlural from '../../../utils/singularPlural.js'

/**
 * StartTimeBottomDisplay Component
 *
 * Displays additional start time information below the main time display,
 * such as day offset indicators (+1 day, etc.)
 */
export default function StartTimeBottomDisplay ({ daysPlus }) {
  const displayText = useMemo(() => {
    if (!daysPlus || daysPlus <= 0) return ''
    return `+${singularPlural(daysPlus, 'day', 'days')}`
  }, [daysPlus])

  if (!displayText) return null

  return (
    <div className="text-xs text-white/60">
      {displayText}
    </div>
  )
}

StartTimeBottomDisplay.propTypes = {
  daysPlus: PropTypes.number,
}
