import CueTimingSchaffold from './CueTimingSchaffold.jsx'
import DurationPopoverContent from './DurationPopoverContent.jsx'
import DurationTopDisplay from './DurationTopDisplay.jsx'
import PropTypes from 'prop-types'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { Popover, PopoverContent, PopoverTrigger } from '../../interactives/Popover'
import { formatDurationHuman } from '../../../utils/formatTime.js'
import { CueRunState } from '@rundown-studio/utils'
import { CueStartMode } from '@rundown-studio/types'

/**
 * CueDuration Component
 *
 * Pure component for displaying and editing a cue's duration.
 * Manages its own internal editing state but triggers external updates via setValues.
 *
 * @param {Object} props Component props
 * @param {Object} props.cue The cue data
 * @param {Object} props.timestamp Timestamp data for the cue
 * @param {boolean} props.loading Loading state
 * @param {Object} props.cellStyle Cell styling
 * @param {boolean} props.readonly Whether editing is disabled
 * @param {Function} props.setValues Callback for duration updates
 * @param {string} props.timezone The timezone
 */
export default function CueTimingDuration ({
  values,
  setValues,
  cellStyle,
  readonly,
  loading,
  timestamp,
  moment,
}) {
  const [open, setOpen] = useState(false)
  const [duration, setDuration] = useState(values.duration)

  // Sync internal state when external cue duration changes
  // This implements the "last-action-wins" conflict management
  useEffect(() => {
    setDuration(values.duration)
  }, [values.duration])

  // Save changes to duration
  // Only update if the duration has actually changed
  const handlePopoverSave = useCallback(() => {
    if (duration === values.duration) return
    setValues({ duration })
  }, [duration, values.duration, setValues])

  // Cancel changes and reset to current cue duration
  const handlePopoverCancel = useCallback(() => {
    setDuration(values.duration)
  }, [values.duration])

  // Combined calculation of duration display text and styling
  const btnMarkup = useMemo(
    () => getButtonMarkup(duration, timestamp, moment),
    [duration, timestamp, moment],
  )

  if (!timestamp) return null

  return (
    <CueTimingSchaffold
      style={cellStyle}
      loading={loading}
      top={(
        <DurationTopDisplay
          cueState={timestamp.state}
          actualDuration={timestamp.actual.duration}
          originalDuration={timestamp.original.duration}
          plannedDuration={values.duration}
        />
      )}
    >
      <Popover
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <PopoverTrigger
          asChild={true}
          disabled={readonly}
        >
          <button
            type="button"
            className={[
              'h-6 px-1.5 min-w-12 rounded-sm bg-black/50 hover:enabled:bg-gray-600/40',
              (open ? 'ring-1 ring-white' : ''),
            ].join(' ')}
          >
            <span className={btnMarkup.className}>
              {btnMarkup.label}
            </span>
          </button>
        </PopoverTrigger>
        <PopoverContent
          onSave={handlePopoverSave}
          onCancel={handlePopoverCancel}
          saveDisabled={readonly}
        >
          <DurationPopoverContent
            duration={duration}
            setDuration={setDuration}
            disabled={readonly}
            cueState={timestamp.state}
            originalDuration={timestamp.original.duration}
            actualDuration={timestamp.actual.duration}
          />
        </PopoverContent>
      </Popover>
    </CueTimingSchaffold>
  )
}

CueTimingDuration.propTypes = {
  values: PropTypes.shape({
    startTime: PropTypes.instanceOf(Date),
    startMode: PropTypes.oneOf(Object.values(CueStartMode)),
    startDatePlus: PropTypes.number,
    scheduled: PropTypes.bool,
    duration: PropTypes.number,
  }),
  setValues: PropTypes.func.isRequired,
  cellStyle: PropTypes.object,
  readonly: PropTypes.bool,
  loading: PropTypes.bool,
  timestamp: PropTypes.object,
  moment: PropTypes.object,
  timezone: PropTypes.string.isRequired,
}

/**
 * Generates button markup (label and styling) based on duration, timestamp and moment
 *
 * @param {number} duration - The planned duration
 * @param {object|null} timestamp - The timestamp object with state and actual duration
 * @param {object|null} moment - The moment object with left property
 * @returns {object} - Object with label and className properties
 */
function getButtonMarkup (duration, timestamp, moment) {
  // Base styles that appear in all cases
  const baseStyle = 'text-center tabular-nums'
  const formatOptions = { prefix: '+', omitZero: true }

  // Early return for missing data
  if (!timestamp || !moment) {
    return {
      label: formatDurationHuman(duration, formatOptions),
      className: baseStyle,
    }
  }

  // Set up variables for our result
  let durationToFormat = duration
  let colorClass = ''

  // Determine what to display based on cue state
  switch (timestamp.state) {
    case CueRunState.CUE_ACTIVE:
      durationToFormat = moment.left

      // Red for overtime
      if (moment.left <= -1000) colorClass = 'text-red-600'
      break

    case CueRunState.CUE_PAST:
      durationToFormat = timestamp.actual.duration

      // Green for under duration (faster than planned)
      if (duration > timestamp.actual.duration) colorClass = 'text-green-600'
      // Red for over duration (slower than planned)
      else if (duration < timestamp.actual.duration) colorClass = 'text-red-600'
      break

    default:
      // Future cues or other states
      durationToFormat = timestamp.actual?.duration || duration
  }

  // Return formatted label and appropriate className
  return {
    label: formatDurationHuman(durationToFormat, formatOptions),
    className: [baseStyle, colorClass].filter(Boolean).join(' '),
  }
}
