import CueTimingSchaffold from './CueTimingSchaffold.jsx'
import StartTimePopoverContent from './StartTimePopoverContent.jsx'
import StartTimeSupDisplay from './StartTimeSupDisplay.jsx'
import StartTimeSubDisplay from './StartTimeSubDisplay.jsx'
import { Popover, PopoverContent, PopoverTrigger } from '../../interactives/Popover.jsx'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { CueRunState, fastDeepEqual } from '@rundown-studio/utils'
import _ from 'lodash'
import { applyDate, formatTimeOfDay } from '@rundown-studio/timeutils'
import floorMs from '../../../utils/floorMs.js'
import { CueStartMode, RunnerState } from '@rundown-studio/types'
import StartTimeTopDisplay from './StartTimeTopDisplay.jsx'
import StartTimeBottomDisplay from './StartTimeBottomDisplay.jsx'
import { addDays } from 'date-fns'
import { tz } from '@date-fns/tz'
import eventBus from '../../../utils/eventBus.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbTack } from '@fortawesome/free-solid-svg-icons'

/**
 * CueStartTime Component
 *
 * Pure component for displaying and editing a cue's start time information.
 * Manages internal state but triggers external updates via setValues.
 */
export default function CueTimingStartTime ({
  cueId,
  values,
  setValues,
  cellStyle,
  readonly,
  loading,
  moment,
  timestamp,
  timezone,
  isFirstCue,
  timingProps,
  nextCue,
}) {
  // -----------------------------------------------
  // State Management
  // -----------------------------------------------
  const [open, setOpen] = useState(false)
  const [invalid] = useState(null)
  const [internalValues, setInternalValues] = useState(values)
  const previousValuesRef = useRef(values)
  const isHardStart = useMemo(() => internalValues.startMode === CueStartMode.FIXED, [internalValues])

  /**
   * Sync internal state when external values change
   *  - This implements the "last-action-wins" conflict management
   *  - Ignore all upstream changes while popover is open
   */
  useEffect(() => {
    if (open) return
    const hasExternalChanges = !fastDeepEqual(values, previousValuesRef.current)
    if (hasExternalChanges) {
      setInternalValues(values)
      previousValuesRef.current = values
    }
  }, [values, open])

  // -----------------------------------------------
  // Popover Handlers
  // -----------------------------------------------
  // Cancel changes and reset to current cue duration
  const handlePopoverCancel = useCallback(() => {
    setInternalValues(values)
  }, [values])

  // Save changes to props
  // Only update if values have actually changed
  const handlePopoverSave = useCallback(({ abort } = {}) => {
    // Compare values and only submit changed values
    const changedProps = {}
    const relevantProps = ['startTime', 'startDatePlus', 'startMode', 'scheduled']

    // Only proceed if props are valid
    if (invalid) {
      return abort?.()
    }

    // Check which props changed
    relevantProps.forEach((prop) => {
      if (!fastDeepEqual(internalValues[prop], values[prop])) {
        changedProps[prop] = internalValues[prop]
      }
    })

    // Only update if there are changes
    if (!_.isEmpty(changedProps)) {
      setValues(changedProps)
    }
  }, [internalValues, values, setValues, invalid])

  // -----------------------------------------------
  // Value Change Handlers
  // -----------------------------------------------
  const calcStartTime = useCallback((startTime, startDatePlus) => {
    const date = addDays(timingProps.rundownStartTime, startDatePlus, { in: tz(timezone) })
    return applyDate(startTime, date, { timezone })
  }, [timingProps, timezone])

  // Save changes to internalValues when properties change
  const onPropChange = useCallback((propName, value) => {
    const update = { ...internalValues, [propName]: value }

    // Handle startDatePlus --> startTime dependency (except for first cue)
    if (!isFirstCue && ['startDatePlus', 'startTime'].includes(propName)) {
      update.startTime = calcStartTime(update.startTime, update.startDatePlus)
    }

    setInternalValues(update)
  }, [internalValues])

  // -----------------------------------------------
  // Scheduling
  // -----------------------------------------------
  const onScheduleToggle = useCallback((newScheduledValue) => {
    setValues({
      scheduled: newScheduledValue ?? !internalValues.scheduled,
    })
  }, [internalValues, setValues])

  useEffect(() => {
    eventBus.$on(`scheduleToggleCue:${cueId}`, onScheduleToggle)
    return () => eventBus.$off(`scheduleToggleCue:${cueId}`, onScheduleToggle)
  }, [cueId, onScheduleToggle])

  const onScheduleToggleNext = useCallback(() => {
    eventBus.$emit(`scheduleToggleCue:${nextCue.id}`)
  }, [nextCue])

  // -----------------------------------------------
  // Visual State Calculations
  // -----------------------------------------------
  // Compute time comparison state for text color
  const timeComparisonState = useMemo(() => {
    if (!timestamp) return 'neutral'

    const originalTime = floorMs(timestamp.original.start)
    const actualTime = floorMs(timestamp.actual.start)

    if (originalTime > actualTime) return 'early'
    if (originalTime < actualTime) return 'late'
    return 'neutral'
  }, [timestamp])

  // Calculate formatted time
  const formattedTime = useMemo(() => {
    if (!timestamp) return ''

    // Always show seconds (00:00:00) for current and future cues to avoid visual countdown jumping.
    let seconds = 'nonzero'
    if (moment?.running && timestamp.state !== CueRunState.CUE_PAST) {
      if (timestamp.state === CueRunState.CUE_ACTIVE || moment.left < 0) seconds = 'always'
    }

    return formatTimeOfDay(timestamp.actual.start, {
      timezone,
      seconds,
      format: timingProps.todDisplayFormat,
    })
  }, [timestamp, timezone, timingProps.todDisplayFormat, moment?.running])

  // -----------------------------------------------
  // Rendering
  // -----------------------------------------------
  if (!timestamp) return null

  const timeTextClasses = {
    neutral: '',
    early: 'text-green-600',
    late: 'text-red-600',
  }

  return (
    <CueTimingSchaffold
      style={cellStyle}
      loading={loading}
      sup={(
        <StartTimeSupDisplay
          startTime={internalValues.startTime}
          startMode={internalValues.startMode}
          scheduled={internalValues.scheduled}
          setScheduled={onScheduleToggle}
          readonly={readonly || moment?.cueId === cueId}
          timezone={timezone}
          todDisplayFormat={timingProps.todDisplayFormat}
        />
      )}
      top={(
        <StartTimeTopDisplay
          timestamp={timestamp}
          timezone={timezone}
          todDisplayFormat={timingProps.todDisplayFormat}
          cellStyle={cellStyle}
        />
      )}
      bottom={(
        <StartTimeBottomDisplay
          daysPlus={timestamp?.actual?.daysPlus}
        />
      )}
      sub={(
        <StartTimeSubDisplay
          active={nextCue && nextCue.scheduled && nextCue.startMode === CueStartMode.FLEXIBLE}
          onToggle={onScheduleToggleNext}
          readonly={readonly || !nextCue || moment?.cueId === nextCue.id || nextCue.startMode !== CueStartMode.FLEXIBLE}
        />
      )}
    >
      <Popover
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <PopoverTrigger
          asChild={true}
          disabled={readonly}
        >
          <button
            type="button"
            className={[
              'h-6 px-1.5 rounded-sm bg-black/50 hover:enabled:bg-gray-600/40 space-x-1',
              'whitespace-nowrap',
              (open ? 'ring-1 ring-white' : ''),
            ].join(' ')}
          >
            {isHardStart && (
              <span className="text-xs" style={{ verticalAlign: '0.05em' }}>
                <FontAwesomeIcon icon={faThumbTack} size="xs" />
              </span>
            )}
            <span className={['tabular-nums', timeTextClasses[timeComparisonState]].join(' ')}>
              {formattedTime}
            </span>
          </button>
        </PopoverTrigger>
        <PopoverContent
          onSave={handlePopoverSave}
          onCancel={handlePopoverCancel}
        >
          <>
            <StartTimePopoverContent
              startTime={internalValues.startTime}
              startDatePlus={internalValues.startDatePlus}
              startMode={internalValues.startMode}
              scheduled={internalValues.scheduled}
              setStartTime={onPropChange.bind(null, 'startTime')}
              setStartDatePlus={onPropChange.bind(null, 'startDatePlus')}
              setStartMode={onPropChange.bind(null, 'startMode')}
              setScheduled={onPropChange.bind(null, 'scheduled')}
              disabled={readonly || timestamp?.state === CueRunState.CUE_ACTIVE}
              timezone={timezone}
              timestamp={timestamp}
              isFirstCue={isFirstCue}
              timingProps={timingProps}
            />
          </>
        </PopoverContent>
      </Popover>
    </CueTimingSchaffold>
  )
}

CueTimingStartTime.propTypes = {
  cueId: PropTypes.string,
  values: PropTypes.shape({
    startTime: PropTypes.instanceOf(Date),
    startMode: PropTypes.oneOf(Object.values(CueStartMode)),
    startDatePlus: PropTypes.number,
    scheduled: PropTypes.bool,
    duration: PropTypes.number,
  }),
  setValues: PropTypes.func.isRequired,
  cellStyle: PropTypes.object,
  readonly: PropTypes.bool,
  loading: PropTypes.bool,
  timestamp: PropTypes.object,
  moment: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  isFirstCue: PropTypes.bool,
  timingProps: PropTypes.shape({
    runnerState: PropTypes.oneOf(Object.values(RunnerState)),
    todDisplayFormat: PropTypes.string,
    rundownStartTime: PropTypes.instanceOf(Date),
  }),
  nextCue: PropTypes.object,
}
