import { useState } from 'react'
import { faCheck, faCheckDouble, faClock, faPencil, faWarning } from '@fortawesome/free-solid-svg-icons'
import { updateRundown } from '../../firestore'
import PropTypes from 'prop-types'
import { Menu, MenuItem } from '../interactives/DropdownMenu'

const statuses = {
  'imported': { name: 'Imported', css: 'text-gray-500 bg-transparent border border-gray-700 border-dashed' },
  'draft': { name: 'Draft', css: 'text-gray-500 bg-gray-950' },
  'awaiting-data': { name: 'Awaiting data', css: 'text-orange-500 bg-orange-900' },
  'approved': { name: 'Approved', css: 'text-green-500 bg-green-900' },
  'finalized': { name: 'Finalized', css: 'text-blue-500 bg-blue-800' },
  'rejected': { name: 'Rejected', css: 'text-red-500 bg-red-900' },
}

const RundownStatus = ({ rundown, onUpdate, disabled = false }) => {
  const [loading, setLoading] = useState(false)

  const updateStatus = async (status) => {
    setLoading(true)
    await updateRundown(rundown?.id, { status })
    onUpdate({ ...rundown, status })
    setLoading(false)
  }

  const contextMenuActions = Object.keys(statuses).reduce((acc, key) => {
    acc[key] = () => updateStatus(key)
    return acc
  }, {})

  const items = Object.entries(statuses).filter(([key]) => key !== 'imported').map(([key, value]) => ({
    name: value.name,
    value: key,
    icon: {
      // 'imported': faFileImport,
      'draft': faPencil,
      'awaiting-data': faClock,
      'approved': faCheck,
      'finalized': faCheckDouble,
      'rejected': faWarning,
    }[key],
    colour: statuses[key].css,
  }))

  return (
    <>
      <Menu
        className={[
          'px-2 py-1 text-xs rounded-sm disabled:pointer-events-none',
          statuses[rundown?.status || 'draft']?.css,
          loading && 'bg-animated from-[#161616] to-black text-gray-500',
        ].join(' ')}
        label={statuses[rundown?.status || 'draft']?.name || rundown?.status}
        disabled={disabled}
      >
        {items.map((item) => {
          return (
            <MenuItem
              key={item.name}
              icon={item.icon}
              label={item.name}
              className={[
                'mb-1',
                item.colour,
              ].join(' ')}
              onClick={() => contextMenuActions[item.value]()}
            />
          )
        })}
      </Menu>
    </>
  )
}

RundownStatus.propTypes = {
  rundown: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default RundownStatus
