import PropTypes from 'prop-types'

export default function Input ({
  label = '',
  value = '',
  type = 'text',
  onChange = () => {},
  onBlur = () => {},
  placeholder = 'Enter text...',
  autofocus = false,
  disabled = false,
  required = false,
  loading = false,
  ...props
}) {
  return (
    <div
      className={[
        loading && 'bg-animated from-gray-900 to-gray-800',
      ].join(' ')}
      {...props}
    >
      <label
        htmlFor={label}
        className="block pt-2 text-blue-500 text-xs"
      >
        {label}
      </label>
      <input
        id={label}
        name={label}
        className="h-9 mt-1 w-full px-3 text-white bg-gray-800 focus:outline-hidden focus:ring-3 rounded-sm"
        type={type}
        disabled={disabled}
        autoFocus={autofocus}
        required={required}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
      />
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}
