import PropTypes from 'prop-types'
import ContextMenu from '../ContextMenu.jsx'
import Button from '../Button.jsx'
import { useSavedState } from '../reusables/SavedStateIndicator.jsx'
import { useEffect, useState, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { faCheckCircle, faPencil } from '@fortawesome/free-solid-svg-icons'
import { updateRundownColumn } from '../../firestore.js'
import { CELL_SELECT_COLORS } from '@rundown-studio/consts'
import _indexOf from 'lodash/indexOf'
import _compact from 'lodash/compact'

export default function CellItemSelect ({
  col,
  cell,
  onUpdateCell,
  readonly = false,
  eventEmitter,
  dataLabel,
}) {
  const { rundownId } = useParams()
  const [showContext, setShowContext] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [options, setOptions] = useState(col.options || [])
  const containerRef = useRef()

  // Setup saved state indicator with the hook
  const {
    indicator: savedIndicator,
    savingStart,
    savingDone,
    savingFail,
  } = useSavedState({ className: 'absolute z-30 bottom-1 right-1' })

  // Empty string '' indicates no option selected
  const selectedVal = cell?.content?.selected || ''

  async function handleUpdate (newValue) {
    if (newValue === 'edit_options') return setEditMode(true)
    savingStart()
    try {
      await onUpdateCell({ content: {
        selected: selectedVal === newValue ? '' : newValue,
      } })
      savingDone()
    } catch {
      savingFail()
    }
  }

  async function handleSetOptions () {
    if (options.length < 0) return
    const optionsCleaned = _compact(options)
    savingStart()
    try {
      setOptions(optionsCleaned)
      setEditMode(false)
      await updateRundownColumn(rundownId, col.id, { options: optionsCleaned })
      savingDone()
    } catch {
      savingFail()
    }
  }

  // Save textarea values on CMD/Strg + Enter
  function onTextareaKeyDown (event) {
    if (event.code === 'Enter' && event.metaKey) return handleSetOptions()
  }

  useEffect(() => {
    setOptions(col.options || [])
  }, [col.options])

  //
  // Handle focus and tab navigation
  //
  const onFocus = useCallback(() => {
    containerRef.current?.firstChild?.focus()
  }, [containerRef.current])

  useEffect(() => {
    eventEmitter.on('focus', onFocus)
    return () => eventEmitter.off('focus', onFocus)
  }, [eventEmitter, onFocus])

  function onContainerKeyDown (event) {
    if (event.target.type !== 'button') return
    // jump to previous focusable element
    if (event.key === 'Tab' && event.shiftKey) {
      return eventEmitter.emit('jump', { direction: 'left', event })
    }
    // jump to next focusable element
    if (event.key === 'Tab') {
      return eventEmitter.emit('jump', { direction: 'right', event })
    }
    // if cursor start, then jump to previous cell possible
    if (event.key === 'ArrowLeft') {
      return eventEmitter.emit('jump', { direction: 'left', event })
    }
    // if cursor end, then jump to next cell possible
    if (event.key === 'ArrowRight') {
      return eventEmitter.emit('jump', { direction: 'right', event })
    }
    // if cursor start, then jump to above cell if possible
    if (event.key === 'ArrowUp') {
      return eventEmitter.emit('jump', { direction: 'up', event })
    }
    // if cursor end, then jump to below cell if possible
    if (event.key === 'ArrowDown') {
      return eventEmitter.emit('jump', { direction: 'down', event })
    }
  }

  function renderContent () {
    // User has no edit right
    if (readonly) return selectedVal
      ? (
          <div
            style={{
              backgroundColor: CELL_SELECT_COLORS[_indexOf(options, selectedVal)],
            }}
            className={[
              'text-center min-h-8 px-4 leading-6 rounded-sm font-light',
            ].join(' ')}
          >
            {selectedVal}
          </div>
        )
      : null

    // Edit mode
    if (isEditMode) return (
      <>
        <textarea
          autoFocus={true}
          className="px-1 bg-transparent focus:outline-hidden focus:ring-3 border border-gray-700 rounded-sm text-sm w-full"
          value={options?.join('\n')}
          rows={4}
          placeholder={'Option 1…\nOption 2…\nOption 3…'}
          onChange={(e) => setOptions(e.target.value.split('\n'))}
          onKeyDown={onTextareaKeyDown}
        />
        <Button
          text="Confirm"
          onClick={handleSetOptions}
          className="font-light! w-full h-6! text-sm"
        />
      </>
    )

    // No options available
    if (!options.length) return (
      <button
        className="w-full h-8 leading-6 bg-gray-800 text-gray-400 rounded-sm font-light hover:brightness-110"
        onClick={() => setEditMode(true)}
        type="button"
      >
        Configure options...
      </button>
    )

    // Valid option selected
    if (selectedVal && options?.includes(selectedVal)) return (
      <button
        style={{
          backgroundColor: CELL_SELECT_COLORS[_indexOf(options, selectedVal)],
        }}
        className={[
          'w-full min-h-8 px-2 rounded-sm font-light hover:brightness-110',
        ].join(' ')}
        onClick={(e) => setShowContext(e.nativeEvent)}
        type="button"
      >
        {selectedVal}
      </button>
    )

    // Default case: No valid option selected
    return (
      <button
        className="w-full min-h-8 px-2 bg-gray-800 text-gray-400 rounded-sm font-light hover:brightness-110"
        onClick={(e) => setShowContext(e.nativeEvent)}
        type="button"
      >
        Choose option...
      </button>
    )
  }

  return (
    <div
      ref={containerRef}
      className="px-3 py-2 w-full h-full space-y-2"
      data-label={dataLabel}
      onKeyDown={onContainerKeyDown}
    >
      {renderContent()}
      {showContext && (
        <ContextMenu
          openEvent={showContext}
          onClose={() => setShowContext(false)}
          onClick={handleUpdate}
          items={[
            ...options.map((option, i) => ({
              name: option,
              value: option,
              colour: CELL_SELECT_COLORS[i],
              icon: selectedVal === option && faCheckCircle,
            })),
            {
              name: 'Edit options',
              value: 'edit_options',
              icon: faPencil,
            },
          ]}
        />
      )}
      {/* Saved state indicator */}
      {savedIndicator}
    </div>
  )
}

CellItemSelect.propTypes = {
  cell: PropTypes.object,
  col: PropTypes.object.isRequired,
  onUpdateCell: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  eventEmitter: PropTypes.object,
  dataLabel: PropTypes.string,
}
