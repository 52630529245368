import Layout from '../../components/Layout.jsx'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const STATUS = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  SERVER_ERROR: 500,
}

export default function RundownError ({ error = new Error('Rundown not found') }) {
  const status = Object.values(STATUS).includes(error?.response?.status) ? error?.response?.status : STATUS.SERVER_ERROR

  function getErrorTitle (status) {
    return {
      [STATUS.UNAUTHORIZED]: 'Access Denied',
      [STATUS.NOT_FOUND]: 'Not Found',
      [STATUS.FORBIDDEN]: 'Access Restricted',
      [STATUS.SERVER_ERROR]: 'Server Error',
    }[status] || 'Error'
  }

  // Determine the appropriate message based on the error type
  function getErrorMessage (status) {
    return {
      [STATUS.UNAUTHORIZED]: 'You don\'t have permission to view this rundown.',
      [STATUS.NOT_FOUND]: 'We couldn\'t find the rundown you\'re looking for.',
      [STATUS.FORBIDDEN]: error?.response?.data?.includes('archived')
        ? 'This rundown has been archived.'
        : 'You don\'t have access to this rundown.',
      [STATUS.SERVER_ERROR]: 'Something went wrong on our end.',
    }[status] || error?.response?.data || error?.message
  }

  return (
    <Layout title={getErrorTitle(status)}>
      <div className="space-y-6">
        <p className="font-medium">
          {getErrorMessage(status)}
        </p>

        {status === STATUS.UNAUTHORIZED && (
          <div className="text-gray-500 space-y-2">
            <p>
              This might be because:
            </p>
            <ul className="text-left">
              <li>• You need to log in to view this rundown</li>
              <li>• You were sent an incorrect link</li>
              <li>• The rundown owner hasn&apos;t given you access</li>
            </ul>
            <Link
              to={`/login?next=${window.location.pathname}`}
              className="underline"
            >
              Go to Login →
            </Link>
          </div>
        )}
        {status === STATUS.NOT_FOUND && (
          <p className="text-gray-500">
            The link you followed might be incorrect or the rundown may have been deleted.
          </p>
        )}
        {status === STATUS.FORBIDDEN && error?.response?.data?.includes('archived') && (
          <p className="text-gray-500">
            Archived rundowns are no longer accessible to prevent accidental changes to past events.
          </p>
        )}
        {status === STATUS.SERVER_ERROR && (
          <div className="text-gray-500 space-y-2">
            <p>
              Don&apos;t worry - this is probably an issue on our end, not something you did.
            </p>
            <p>
              If this keeps happening, please contact us at
              {' '}
              <a
                href="mailto:hello@rundownstudio.app"
                className="underline hover:text-gray-400"
              >
                hello@rundownstudio.app
              </a>
            </p>
          </div>
        )}

        <hr className="border-gray-800" />

        {/* We include the actual error message here for when people send us screenshots */}
        <div className="text-sm text-gray-700 text-center">
          &quot;[{error?.response?.status}] {error?.response?.data || error?.message}&quot;
        </div>
      </div>
    </Layout>
  )
}

RundownError.propTypes = {
  error: PropTypes.instanceOf(Error),
}
