import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTransitionStyles,
  useTypeahead,
} from '@floating-ui/react'
import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

export const MenuComponent = forwardRef(({ children, icon, label, className, placement, loading, ...props }, forwardedRef) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [allowHover, setAllowHover] = useState(false)
  // const [hasFocusInside, setHasFocusInside] = useState(false)

  const listItemsRef = useRef([])
  const listContentRef = useRef([])

  useEffect(() => {
    const strings = []
    Children.forEach(children, (child) => {
      if (isValidElement(child)) {
        strings.push(
          child.props.label && !child.props.disabled
            ? child.props.label
            : null,
        )
      }
    })
    listContentRef.current = strings
  })

  const tree = useFloatingTree()
  const nodeId = useFloatingNodeId()
  const parentId = useFloatingParentNodeId()
  const isNested = parentId != null

  const { refs, floatingStyles, context } = useFloating({
    nodeId,
    transform: false,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement || isNested ? 'right-start' : 'bottom-start',
    middleware: [
      offset({
        mainAxis: isNested ? 2 : 4,
        alignmentAxis: isNested ? -4 : 0,
      }),
      flip(),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  })

  const hover = useHover(context, {
    enabled: isNested && allowHover,
    delay: { open: 75 },
    handleClose: safePolygon({
      blockPointerEvents: true,
    }),
  })
  const click = useClick(context, {
    event: 'mousedown',
    toggle: !isNested || !allowHover,
    ignoreMouse: isNested,
  })
  const role = useRole(context, { role: 'menu' })
  const dismiss = useDismiss(context, { bubbles: true })
  const listNavigation = useListNavigation(context, {
    listRef: listItemsRef,
    activeIndex,
    nested: isNested,
    onNavigate: setActiveIndex,
  })
  const typeahead = useTypeahead(context, {
    enabled: isOpen,
    listRef: listContentRef,
    onMatch: isOpen ? setActiveIndex : undefined,
    activeIndex,
  })

  const { getReferenceProps, getFloatingProps, getItemProps }
    = useInteractions([
      hover,
      click,
      role,
      dismiss,
      listNavigation,
      typeahead,
    ])

  // Event emitter allows you to communicate across tree components.
  // This effect closes all menus when an item gets clicked anywhere
  // in the tree.
  useEffect(() => {
    if (!tree) return

    function handleTreeClick() {
      setIsOpen(false)
    }

    function onSubMenuOpen(event) {
      if (
        event.nodeId !== nodeId
        && event.parentId === parentId
      ) {
        setIsOpen(false)
      }
    }

    tree.events.on('click', handleTreeClick)
    tree.events.on('menuopen', onSubMenuOpen)

    return () => {
      tree.events.off('click', handleTreeClick)
      tree.events.off('menuopen', onSubMenuOpen)
    }
  }, [tree, nodeId, parentId])

  useEffect(() => {
    if (isOpen && tree) {
      tree.events.emit('menuopen', { parentId, nodeId })
    }
  }, [tree, isOpen, nodeId, parentId])

  // Determine if "hover" logic can run based on the modality of input. This
  // prevents unwanted focus synchronization as menus open and close with
  // keyboard navigation and the cursor is resting on the menu.
  useEffect(() => {
    function onPointerMove({ pointerType }) {
      if (pointerType !== 'touch') {
        setAllowHover(true)
      }
    }

    function onKeyDown() {
      setAllowHover(false)
    }

    window.addEventListener('pointermove', onPointerMove, {
      once: true,
      capture: true,
    })
    window.addEventListener('keydown', onKeyDown, true)
    return () => {
      window.removeEventListener(
        'pointermove',
        onPointerMove,
        {
          capture: true,
        },
      )
      window.removeEventListener('keydown', onKeyDown, true)
    }
  }, [allowHover])

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: { open: 100 },
  })

  const referenceRef = useMergeRefs([
    refs.setReference,
    forwardedRef,
  ])
  const referenceProps = getReferenceProps({
    ...props,
    onFocus(event) {
      props.onFocus?.(event)
      // setHasFocusInside(false)
    },
    onClick(event) {
      event.stopPropagation()
    },
    ...(isNested && {
      // Indicates this is a nested <Menu /> acting as a <MenuItem />.
      role: 'menuitem',
    }),
  })

  return (
    <FloatingNode id={nodeId}>
      {isNested
        ? (
            <button
              ref={referenceRef}
              data-open={isOpen ? '' : undefined}
              role="menuitem"
              {...referenceProps}
              className={[
                'flex items-center rounded-sm h-7 pr-3 text-left outline-hidden',
                'focus:bg-gray-900 text-white font-light text-sm',
                'disabled:bg-black disabled:opacity-40 disabled:pointer-events-none',
                isOpen ? 'bg-gray-900' : '',
                className,
              ].join(' ')}
            >
              {icon && (
                <span className="w-7 text-center">
                  <FontAwesomeIcon
                    icon={icon}
                    size="xs"
                    fixedWidth
                  />
                </span>
              )}
              <span className="grow mr-2">
                {label}
              </span>
              <FontAwesomeIcon
                icon={faChevronRight}
                size="xs"
              />
            </button>
          )
        : (
            <button
              ref={referenceRef}
              data-open={isOpen ? '' : undefined}
              {...referenceProps}
              className={[
                'flex cursor-pointer items-center justify-between gap-4 h-9 px-3',
                'transition-colors rounded-sm bg-gray-900 hover:enabled:bg-gray-800 text-white h-8 text-left text-sm font-semibold overflow-hidden',
                'disabled:opacity-50 disabled:cursor-default focus:outline-hidden focus:ring-3 focus:ring-offset-1 focus:ring-offset-transparent whitespace-nowrap',
                loading ? 'bg-animated from-gray-800 to-gray-600' : '',
                className,
              ].join(' ')}
            >
              {label && <div className="grow truncate">{label}</div>}
              {icon && (
                <div className="flex-none">
                  <FontAwesomeIcon
                    icon={icon}
                    size="sm"
                    fixedWidth
                  />
                </div>
              )}
            </button>
          )}
      {isMounted && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            // Prevent outside content interference.
            modal={false}
            // Only initially focus the root floating menu.
            initialFocus={isNested ? -1 : 0}
            // Only return focus to the root menu's reference when menus close.
            returnFocus={!isNested}
          >
            <div
              ref={refs.setFloating}
              className="outline-hidden"
              style={{
                ...floatingStyles,
                width: 'max-content',
                ...styles,
                zIndex: 1000,
              }}
              data-label="dropdown-menu-floating-portal"
              {...getFloatingProps()}
            >
              <div
                className="flex flex-col rounded-sm border border-white/10 bg-black/80 bg-clip-padding p-1 shadow-lg backdrop-blur-lg"
              >
                {Children.map(
                  children,
                  (child, index) =>
                    isValidElement(child)
                    && cloneElement(
                      child,
                      getItemProps({
                        tabIndex: activeIndex === index ? 0 : -1,
                        ref(node) {
                          listItemsRef.current[index] = node
                        },
                        onClick(event) {
                          child.props.onClick?.(event)
                          tree?.events.emit('click')
                        },
                        onFocus(event) {
                          child.props.onFocus?.(event)
                          // setHasFocusInside(true)
                        },
                        // Allow focus synchronization if the cursor did not move.
                        onMouseEnter() {
                          if (allowHover && isOpen) {
                            setActiveIndex(index)
                          }
                        },
                      }),
                    ),
                )}
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </FloatingNode>
  )
})
MenuComponent.displayName = 'MenuComponent'
MenuComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  icon: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onFocus: PropTypes.func,
  className: PropTypes.string,
  placement: PropTypes.string,
  loading: PropTypes.bool,
}

export const Menu = forwardRef((props, ref) => {
  const parentId = useFloatingParentNodeId()

  if (parentId === null) return (
    <FloatingTree>
      <MenuComponent {...props} ref={ref} />
    </FloatingTree>
  )

  return <MenuComponent {...props} ref={ref} />
})
Menu.displayName = 'Menu'
Menu.propTypes = {}

export const MenuItem = forwardRef(({ icon, active, label, disabled, className, ...props }, ref) => {
  return (
    <button
      type="button"
      {...props}
      className={[
        'flex items-center rounded-sm h-7 pr-3 text-left outline-hidden',
        'focus:bg-gray-900 text-white font-light text-sm',
        'disabled:bg-black disabled:opacity-40 disabled:-z-10 disabled:pointer-events-none',
        (icon ? '' : 'pl-3'),
        className,
      ].join(' ')}
      ref={ref}
      role="menuitem"
      disabled={disabled}
    >
      {icon && (
        <span className="w-7 text-center">
          <FontAwesomeIcon
            icon={icon}
            size="xs"
            fixedWidth
          />
        </span>
      )}
      <span className="grow">
        {label}
      </span>
      {active && (
        <span className="w-7 text-right">
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="xs"
            fixedWidth
          />
        </span>
      )}
    </button>
  )
})
MenuItem.displayName = 'MenuItem'
MenuItem.propTypes = {
  icon: PropTypes.object,
  active: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export const MenuSeparator = forwardRef(({ ...props }, ref) => {
  return (
    <hr
      {...props}
      ref={ref}
      className="border-white/10 my-1"
      tabIndex={undefined}
      disabled
    />
  )
})
MenuSeparator.displayName = 'MenuSeparator'
MenuSeparator.propTypes = {
}
