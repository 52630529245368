import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import formatTimezone from '../../utils/formatTimezone.js'
import formatTimeOfDay from '../../utils/formatTimeOfDay.js'
import { useAtomValue } from 'jotai'
import { getNowAtom } from '../../store/runner.store.js'

export default function CurrentTimeOfDay ({ timezone, omitSeconds = false, showTimezone = false, todDisplayFormat }) {
  const getNow = useAtomValue(getNowAtom)
  const [t, setT] = useState(getNow())

  // Update the time 't' every 500 ms (and don't cause infinite update loops)
  useEffect(() => {
    const timeoutId = setInterval(() => setT(getNow()), 500)
    return () => clearInterval(timeoutId)
  }, [])

  const formattedTod = formatTimeOfDay(t, { timezone, seconds: omitSeconds ? 'never' : 'always', format: todDisplayFormat })

  if (timezone && showTimezone) return `${formattedTod} (${formatTimezone(timezone, 'abbr')})`
  return formattedTod
}

CurrentTimeOfDay.propTypes = {
  timezone: PropTypes.string,
  omitSeconds: PropTypes.bool,
  showTimezone: PropTypes.bool,
}
