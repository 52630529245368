export default function createMoment (
  timesnap = null,
  now = undefined,
) {
  const tenMin = 60000
  if (!now || now <= 0) now = Date.now()
  const ts = _timesnapDefaults(timesnap)

  // Calculate 'total' (round 10 ms due to inaccuracies)
  const total = ts.deadline ? ts.deadline - ts.kickoff : tenMin

  // Calculate 'left'
  let left
  if (ts.cueId === null) left = 0 // Runner has eneded
  else if (!ts.deadline) left = 0
  else if (ts.running) left = ts.deadline - now
  else left = ts.deadline - ts.lastStop

  // Correct edge-case where left > total
  if (left > total) left = total

  // Calculate 'time'
  const stop = false

  return {
    ...ts,
    total: _floor50(total),
    left: _floor50(left),
    countdown: _floor50(stop ? 0 : left),
    countup: _floor50(stop ? total : total - left),
    ended: ts.cueId === null,
  }
}

function _floor50(num) {
  return Math.floor(num / 50) * 50
}

function _timesnapDefaults(timesnap) {
  return {
    cueId: null,
    running: false,
    kickoff: 0,
    lastStop: 0,
    deadline: 0,
    ...timesnap,
  }
}
