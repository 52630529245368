import ModalWrapper from './ModalWrapper.jsx'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { updateRundown } from '../../firestore.js'
import { useAtom } from 'jotai'
import { rundownAtom } from '../../store/rundown.store.js'
import DateInput from '../rundown/DateInput.jsx'
import { format, isPast, isToday } from 'date-fns'
import { tz } from '@date-fns/tz'
import { applyDate } from '@rundown-studio/timeutils'

export default function RundownDateModal ({
  open,
  setOpen,
}) {
  const [loading, setLoading] = useState(false)
  const [rundown, setRundown] = useAtom(rundownAtom)
  const [internalStartDate, setInternalStartDate] = useState(rundown.startTime)

  const isPastDate = useMemo(
    () => !isToday(internalStartDate, { in: tz(rundown.timezone) }) && isPast(internalStartDate),
    [internalStartDate],
  )

  function updateInternalStartDate (newDate) {
    const adjustedDate = applyDate(rundown.startTime, newDate, { timezone: rundown.timezone })
    setInternalStartDate(adjustedDate)
  }

  async function onSave () {
    setLoading(true)
    const { data } = await updateRundown(rundown.id, { startTime: internalStartDate })
    setRundown(data)
    setLoading(false)
    setOpen(false)
  }

  function onKeyDown (e) {
    if (e.key === 'Enter') onSave()
    if (e.key === 'Escape') setOpen(false)
  }

  return (
    <ModalWrapper
      title="Select Rundown Date"
      open={[open, setOpen]}
      working={loading}
      buttons={[
        {
          text: 'Cancel',
          colour: 'dark',
          onClick: () => setOpen(false),
        },
        {
          icon: faCheck,
          text: 'Save',
          onClick: () => onSave(),
        },
      ]}
    >
      <div className="text-center">
        <DateInput
          className={[
            '!bg-gray-800 hover:brightness-100 !h-9 !px-3 !w-64',
            isPastDate ? 'ring-2 ring-red-400/60' : '',
          ].join(' ')}
          date={rundown.startTime}
          timezone={rundown.timezone}
          updateDate={updateInternalStartDate}
          loading={loading}
          onKeyDown={onKeyDown}
        />
        <p className="text-neutral-400 mt-2">
          Rundown starts on {format(internalStartDate, 'PPPP')}
        </p>
        {isPastDate && (
          <p className="text-red-400/80 mt-1">
            ⚠️ This date is in the past
          </p>
        )}
      </div>
    </ModalWrapper>
  )
}

RundownDateModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}
