import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import Layout from '../components/Layout'
import Input from '../components/Input'
import Button from '../components/Button'
import { useSetAtom } from 'jotai'
import { toastAtom } from '../store/toast.store.js'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loggingIn, setLoggingIn] = useState(false)
  // const [error, setError] = useState('')

  const addToast = useSetAtom(toastAtom)

  const navigate = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault()

    if (!email || !password) return null

    // setError('')
    setLoggingIn(true)

    const auth = getAuth()

    await signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        // Signed in
        setLoggingIn(false)

        const params = new URLSearchParams(window.location.search)
        const next = params.get('next')

        if (next) return navigate(next)

        return navigate('/dashboard')
      })
      .catch((error) => {
        const errorCode = error.code
        setLoggingIn(false)
        console.error(errorCode)
        switch (errorCode) {
          case 'auth/user-not-found':
            addToast({ title: 'We couldn\'t find your account', type: 'fail' })
            break
          case 'auth/wrong-password':
            addToast({ title: 'Your email and password did not match', type: 'fail' })
            break

          default:
            addToast({ title: 'An error occurred', body: 'Please try again', type: 'fail' })
            break
        }
      })
  }
  return (
    <Layout title="Login" announcement>
      <form onSubmit={(e) => handleLogin(e)} className="flex flex-col gap-4">
        <Input
          label="Email"
          type="email"
          data-label="email-input"
          placeholder="me@email.com"
          autofocus={true}
          value={email}
          onChange={(change) => setEmail(change)}
        />
        <Input
          label="Password"
          type="password"
          data-label="password-input"
          placeholder="••••••••••"
          value={password}
          onChange={(change) => setPassword(change)}
        />
        <Button
          data-label="login-button"
          disabled={!email || !password || loggingIn}
          loading={loggingIn}
          text={<span>{loggingIn ? 'Logging you in...' : 'Login'}</span>}
          onClick={() => {}}
          type="submit"
        />
      </form>

      <p className="text-sm text-gray-200">
        Don&apos;t have an account?
        {' '}
        <Link to={`/signup${window?.location?.search}`} className="text-blue-500 hover:underline">
          Sign up here
        </Link>
      </p>
      <p className="text-sm text-gray-200">
        <Link to="/reset" className="text-blue-500 hover:underline">
          Reset password
        </Link>
      </p>
    </Layout>
  )
}
