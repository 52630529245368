import PropTypes from 'prop-types'

export default function DropzoneIndicator ({ className, visible }) {
  return (
    <div
      className={[
        className,
        'pointer-events-none max-w-4xl bg-black rounded px-1 py-1.5',
        (visible ? 'z-20 opacity-100' : '-z-10 opacity-0'),
      ].join(' ')}
    >
      <div className="w-full h-0.5 bg-blue-500 rounded" />
    </div>
  )
}

DropzoneIndicator.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
}
