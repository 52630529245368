import { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { getUserTeams } from '../firebase'
import Select from '../components/Select'
import UpgradeMessage from '../components/UpgradeMessage'
import { getTeamPlan } from '../firestore'
import Button from '../components/Button'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default function CheckoutPre() {
  const [searchParams] = useSearchParams()
  const [team, setTeam] = useState(null)
  const [teams, setTeams] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasPlan, setHasPlan] = useState(false)
  const [urlQuery, setUrlQuery] = useState(null)
  const navigate = useNavigate()
  const planId = searchParams.get('plan_id')
  const discountCode = searchParams.get('discount_code')
  const discountId = searchParams.get('discount_id')
  const additionalTeamMembersId = searchParams.get('additional_members_id')
  const additionalTeamMembersCount = searchParams.get('additional_members_count')

  async function checkPlanAndNavigate () {
    // Check if the team already has an active subscription or plan
    setLoading(true)
    setHasPlan(false)
    const { data: currentPlan } = await getTeamPlan(team).finally(() => setLoading(false))

    // Structure the URL
    const query = new URLSearchParams({ plan_id: planId, team_id: team })
    if (discountCode) query.set('discount_code', discountCode)
    else if (discountId) query.set('discount_id', discountId)
    if (additionalTeamMembersId) query.set('additional_members_id', additionalTeamMembersId)
    if (additionalTeamMembersCount) query.set('additional_members_count', additionalTeamMembersCount)
    setUrlQuery(query)

    // If user has no current plan
    if (!currentPlan.billingCycle) return navigate(`/checkout?${query}`, { replace: true })

    // If user has a current plan, ask them to reach out
    setHasPlan(true)
  }

  useEffect(() => {
    if (!team) return
    if (!planId) return
    checkPlanAndNavigate()
  }, [team])

  const checkUserAndTeam = async () => {
    const awaitedUserTeams = await getUserTeams()
    setTeams(awaitedUserTeams)
    if (awaitedUserTeams.length === 1) setTeam(awaitedUserTeams[0].id)
  }

  useEffect(() => {
    checkUserAndTeam()
  }, [])

  return (
    <Layout title="Checkout">
      {!planId
        ? <UpgradeMessage message="Missing a product." />
        : (
            <>
              <Select
                label="Team"
                value={team}
                required={teams?.length > 0}
                options={[
                  {
                    name: 'Choose a team...',
                    value: '',
                  },
                  ...teams.map((team) => ({
                    name: team.name,
                    value: team.id,
                  })),
                ]}
                onChange={(teamId) => {
                  setTeam(teamId)
                }}
                loading={!teams.length || loading}
              />
              { hasPlan
                ? (
                    <div className="flex flex-col gap-4">
                      <p className="text-sm">It looks like you already have an active payment plan. Contact us to upgrade your current plan.</p>
                      <div className="flex gap-4 items-baseline">
                        <Link to="mailto:hello@rundownstudio.app">
                          <Button
                            text="Contact support"
                            icon={faEnvelope}
                          />
                        </Link>
                        <Link to={`/checkout?${urlQuery}`} className="text-sm text-white/80 hover:underline hover:text-white">Proceed to checkout</Link>
                      </div>
                    </div>
                  )
                : ''}
            </>
          )}
    </Layout>
  )
}
