import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../interactives/Tooltip.jsx'
import { useMemo } from 'react'

/**
 * StartTimeSupDisplay Component
 *
 * sub = below content
 *
 * Displays a toggle button for controlling soft scheduling (auto-advance)
 * in the top section of a cue timing cell.
 */
export default function StartTimeSubDisplay({
  active = false,
  onToggle,
  readonly = false,
}) {
  const tooltipText = useMemo(() => {
    if (active && readonly) return 'Next cue auto-starts after this cue'
    if (active) return 'Disable auto-start of next cue'
    if (!active) return 'Enable auto-start of next cue'
    return ''
  }, [active, readonly])

  if (readonly && !active) return null

  return (
    <Tooltip disabled={!tooltipText}>
      <TooltipTrigger>
        <button
          className={[
            'relative block w-full h-5 text-center text-xs leading-0 rounded-b transition-colors bg-white/0 text-white',
            (readonly ? 'cursor-default!' : 'hover:bg-white/10'),
          ].join(' ')}
          onClick={onToggle}
          disabled={readonly}
        >
          <div className={(active ? 'opacity-100' : 'opacity-0 group-hover:opacity-40')}>
            <div className="absolute -bottom-2 left-1/2 -ml-[0.5px] w-px h-[16px] rounded bg-current" />
            <FontAwesomeIcon icon={faAngleDown} size="xs" />
          </div>
        </button>
      </TooltipTrigger>
      <TooltipContent>
        <span className="text-xs">
          {tooltipText}
        </span>
      </TooltipContent>
    </Tooltip>
  )
}

StartTimeSubDisplay.propTypes = {
  active: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
}
