import PropTypes from 'prop-types'
import { CueRunState } from '@rundown-studio/utils'
import { formatDurationHuman } from '../../../utils/formatTime.js'

/**
 * DurationTopDisplay Component
 *
 * Displays the original/planned duration above the main duration display when relevant.
 * Smart component that determines its own visibility based on cue state and duration differences.
 */
export default function DurationTopDisplay ({
  cueState,
  actualDuration,
  originalDuration,
  plannedDuration,
}) {
  // Determine whether the component should display anything
  const shouldDisplay = determineVisibility(cueState, actualDuration, originalDuration, plannedDuration)

  // If nothing to display, return null
  if (!shouldDisplay) return null

  // Calculate the appropriate text to display
  const displayText = getDisplayText(cueState, actualDuration, originalDuration, plannedDuration)

  // Determine if text should be strikethrough
  const shouldStrikethrough = determineStrikethrough(cueState, actualDuration, originalDuration)

  return (
    <p className="text-xs text-white/60 tabular-nums truncate">
      <span className={shouldStrikethrough ? 'line-through' : ''}>
        {displayText}
      </span>
    </p>
  )
}

DurationTopDisplay.propTypes = {
  cueState: PropTypes.oneOf(Object.values(CueRunState)),
  actualDuration: PropTypes.number,
  originalDuration: PropTypes.number,
  plannedDuration: PropTypes.number,
}

/**
 * Determines whether the top display should be shown
 */
function determineVisibility (cueState, actualDuration, originalDuration, plannedDuration) {
  // For active or past cues, show the planned duration if different from actual
  if ([CueRunState.CUE_ACTIVE, CueRunState.CUE_PAST].includes(cueState)) {
    return plannedDuration !== actualDuration
  }

  // For future cues, show original duration if it has changed
  if ([CueRunState.CUE_NEXT, CueRunState.CUE_FUTURE].includes(cueState)) {
    return originalDuration !== actualDuration
  }

  return false
}

/**
 * Gets the text to display in the top component
 */
function getDisplayText (cueState, actualDuration, originalDuration, plannedDuration) {
  // For active/past cues, show the planned duration
  if ([CueRunState.CUE_ACTIVE, CueRunState.CUE_PAST].includes(cueState)) {
    return formatDurationHuman(plannedDuration)
  }

  // For future cues, show the original duration
  return formatDurationHuman(originalDuration)
}

/**
 * Determines if the text should have strikethrough styling
 */
function determineStrikethrough (cueState, actualDuration, originalDuration) {
  // Past cues always get strikethrough
  if (cueState === CueRunState.CUE_PAST) {
    return true
  }

  // Future cues with changed duration get strikethrough
  if ([CueRunState.CUE_NEXT, CueRunState.CUE_FUTURE].includes(cueState)) {
    return originalDuration !== actualDuration
  }

  return false
}
