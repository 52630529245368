import PropTypes from 'prop-types'

export default function EditableHms ({
  hms,
  onUpdate,
  onEnter = () => {},
  disabled = false,
}) {
  function handleTimeUpdate (update = {}) {
    const newHms = { ...hms, ...update }

    Object.keys(newHms).forEach((key) => {
      const maxValue = key === 'hours' ? 99 : 59
      const value = String(newHms[key]).padStart(2, '0')
      const newDigit = value.slice(-1) // get the last digit entered
      const currentDigit = value.slice(-2, -1) // get the second last digit

      let newValue = parseInt(currentDigit + newDigit, 10) // combine digits

      // If user enters a letter, ensure we do not store that
      if (isNaN(newValue)) newValue = 0

      if (newValue > maxValue) {
        newValue = parseInt(newDigit, 10) // if it exceeds, use the last digit only
      }

      newHms[key] = newValue
    })

    onUpdate(newHms)
  }

  function handleKeyDown(field, e) {
    if (disabled) return

    const maxValue = field === 'hours' ? 99 : 59
    const currentValue = hms[field] || 0

    if (e.key === 'ArrowUp') {
      e.preventDefault()
      const newValue = currentValue >= maxValue ? 0 : currentValue + 1
      handleTimeUpdate({ [field]: newValue })
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      const newValue = currentValue <= 0 ? maxValue : currentValue - 1
      handleTimeUpdate({ [field]: newValue })
    } else if (e.key === 'Enter') {
      onEnter?.()
    }
  }

  return (
    <div className="flex justify-between items-center font-mono text-sm group/hms gap-0.5">
      <input
        className="w-1/3 h-7 bg-gray-800 enabled:group-hover/hms:brightness-125 rounded-sm text-center focus:outline-hidden focus:ring-3"
        placeholder="00"
        disabled={disabled}
        value={hms?.hours?.toLocaleString('en', { minimumIntegerDigits: 2 })}
        onChange={(e) => handleTimeUpdate({ hours: parseInt(e.target.value || 0) })}
        onKeyDown={(e) => handleKeyDown('hours', e)}
      />
      <span>:</span>
      <input
        className="w-1/3 h-7 bg-gray-800 enabled:group-hover/hms:brightness-125 rounded-sm text-center focus:outline-hidden focus:ring-3"
        placeholder="00"
        disabled={disabled}
        value={hms?.minutes?.toLocaleString('en', { minimumIntegerDigits: 2 })}
        onChange={(e) => handleTimeUpdate({ minutes: parseInt(e.target.value || 0) })}
        onKeyDown={(e) => handleKeyDown('minutes', e)}
      />
      <span>:</span>
      <input
        className="w-1/3 h-7 bg-gray-800 enabled:group-hover/hms:brightness-125 rounded-sm text-center focus:outline-hidden focus:ring-3"
        placeholder="00"
        disabled={disabled}
        value={hms?.seconds?.toLocaleString('en', { minimumIntegerDigits: 2 })}
        onChange={(e) => handleTimeUpdate({ seconds: parseInt(e.target.value || 0) })}
        onKeyDown={(e) => handleKeyDown('seconds', e)}
      />
    </div>
  )
}

EditableHms.propTypes = {
  hms: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  disabled: PropTypes.bool,
}
