import { useEffect } from 'react'
import { useSetAtom } from 'jotai'
import { toastAtom } from '../../store/toast.store.js'
import { pingAtom, timeOffsetAtom } from '../../store/runner.store.js'
import axios from 'axios'
import * as logger from '../../utils/logger.js'

/**
 * Constants for timing and retry configuration
 */
const INITIAL_DELAY = 2000 // Delay before first ping to let page load
const DEFAULT_DELAY = 10 * 1000 // Regular ping interval
const WARN_TIMEOUT = 2 * 1000 // Show warning if ping takes longer than this
const ERROR_TIMEOUT = 5 * 1000 // Consider ping failed after this time
const BACKOFF_AFTER_RETRIES = 10 // Start increasing delay after this many retries
const STOP_AFTER_RETRIES = 30 // Stop retrying after this many attempts
const SLOW_WARNING_AFTER_PINGS = 1 // Only show slow warning after this many pings

/**
 * Custom axios instance for ping requests without auth overhead
 */
const pingApi = axios.create({
  timeout: ERROR_TIMEOUT,
  baseURL: import.meta.env.VITE_RUNNER_FUNCTION_BASE,
})

/**
 * ConnectionChecker monitors network connectivity and synchronizes client time with server.
 * It periodically pings the server to:
 * 1. Monitor connection health and show appropriate status toasts
 * 2. Measure network latency
 * 3. Synchronize client clock with server time
 */
export default function ConnectionChecker () {
  const addToast = useSetAtom(toastAtom)
  const setPing = useSetAtom(pingAtom)
  const setTimeOffset = useSetAtom(timeOffsetAtom)

  useEffect(() => {
    let enabled = true
    let retries = 0
    let delay = DEFAULT_DELAY
    let lowestPing = Number.MAX_SAFE_INTEGER
    let connSlowToast = null
    let connLostToast = null
    let connFinalToast = null
    let pingCount = 0
    const controller = new AbortController()

    /**
     * Creates and shows a toast notification for slow connection
     * Only shows the warning after a configurable number of pings
     */
    function showSlowConnectionWarning () {
      if (enabled && !connLostToast?.visible() && pingCount > SLOW_WARNING_AFTER_PINGS) {
        connSlowToast = addToast({
          title: 'Your connection is slow',
          type: 'warn',
          duration: 6000,
        })
      }
    }

    /**
     * Updates the time synchronization when a new lowest ping is achieved
     * @param {number} clientStartTime - Timestamp when ping request started
     * @param {number} serverTime - Server's timestamp from response
     * @param {number} pingDuration - Round-trip time of the ping
     */
    function updateTimeSync (clientStartTime, serverTime, pingDuration) {
      if (pingDuration < lowestPing) {
        lowestPing = pingDuration
        // Approximate server time by adding half the ping duration to start time
        const approximateServerTime = clientStartTime + (pingDuration / 2)
        const offset = serverTime - approximateServerTime
        setTimeOffset(offset)
        logger.info('[ConnectionChecker] Set server time offset', offset)
      }
    }

    /**
     * Handles successful ping response
     * @param {number} clientStartTime - Timestamp when request started
     * @param {Object} response - Server response
     */
    function handlePingSuccess (clientStartTime, response) {
      const clientEndTime = Date.now()
      const serverTime = response.data.time
      const pingDuration = clientEndTime - clientStartTime

      setPing(pingDuration)
      updateTimeSync(clientStartTime, serverTime, pingDuration)

      if (connLostToast) {
        connLostToast?.dismiss()
        connLostToast = null
        addToast({
          title: 'Reconnected successfully',
          type: 'success',
          duration: 6000,
        })
      }

      pingCount++
      if (pingCount === 1) {
        logger.info('[ConnectionChecker] Initial ping successful', pingDuration)
      }

      retries = 0
      delay = DEFAULT_DELAY
    }

    /**
     * Handles ping failure and implements retry strategy
     */
    function handlePingFailure () {
      setPing(ERROR_TIMEOUT)
      connSlowToast?.dismiss()
      if (!enabled) return

      // Count failures as pings too, so we can start showing warnings
      pingCount++

      retries++
      if (retries >= BACKOFF_AFTER_RETRIES) delay *= 2

      if (!connLostToast?.visible()) {
        connLostToast = addToast({
          title: 'Connection issue. Retrying...',
          type: 'fail',
          duration: -1,
          dismissible: true,
        })
      }

      // Stop retrying after max attempts
      if (retries > STOP_AFTER_RETRIES) {
        connLostToast?.dismiss()
        connFinalToast = addToast({
          title: 'Can\'t reconnect to the server. Please check your network.',
          type: 'fail',
          duration: -1,
          dismissible: true,
        })
        return true // Signal to stop pinging
      }
      return false
    }

    /**
     * Main ping function that checks connection and syncs time with server
     */
    async function pingServer () {
      let connSlowTimeoutId = null

      try {
        const clientStartTime = Date.now()

        connSlowTimeoutId = setTimeout(showSlowConnectionWarning, WARN_TIMEOUT)
        const response = await pingApi.get('ping', { signal: controller.signal })
        clearTimeout(connSlowTimeoutId)

        if (response.status < 400) {
          handlePingSuccess(clientStartTime, response)
        }
      } catch {
        clearTimeout(connSlowTimeoutId)
        const shouldStop = handlePingFailure()
        if (shouldStop) return
      }

      if (enabled) setTimeout(pingServer, delay)
    }

    // Start pinging after initial delay
    const initialTimer = setTimeout(pingServer, INITIAL_DELAY)

    // Cleanup function
    return () => {
      enabled = false
      controller.abort()
      clearTimeout(initialTimer)
      connLostToast?.dismiss()
      connSlowToast?.dismiss()
      connFinalToast?.dismiss()
    }
  }, [addToast, setPing, setTimeOffset])

  return null
}
