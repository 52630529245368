import PropTypes from 'prop-types'
import { useAtomValue } from 'jotai'
import { rundownAtom } from '../../../store/rundown.store.js'
import { CUE_TYPE_CUE, CUE_TYPE_GROUP, CUE_TYPE_HEADING } from '../../../constants/cueTypes.js'
import ScrollIntoView from '../ScrollIntoView.jsx'
import { CueItemElapsed } from '../CueItemHeader.jsx'
import * as OUTPUT_TYPES from '../../../constants/outputConfigTypes.js'
import { CELL_SELECT_COLORS } from '@rundown-studio/consts'
import { CELL_SELECT } from '../../../constants/cellTypes.js'
import { CueLoadingProvider } from '../CueLoadingProvider.jsx'
import CellItemText from '../CellItemText.jsx'
import { useSearchParams } from 'react-router-dom'
import { useMemo, useState } from 'react'
import _indexOf from 'lodash/indexOf'
import mitt from 'mitt'
import { CueRunState } from '@rundown-studio/utils'
import { addMilliseconds } from 'date-fns'
import formatTimeOfDay from '../../../utils/formatTimeOfDay.js'
import { formatDurationHuman } from '../../../utils/formatTime.js'

export default function RundownOutputCueList ({ cues, timestamps, moment, cells, visibleColumns }) {
  const rundown = useAtomValue(rundownAtom)

  let displayIndex = 0

  return (
    <div className="p-2 flex flex-col gap-4 mb-[90vh]">
      {/* Column titles */}
      <div className="sticky top-0 grid gap-4 z-50 bg-black grid-cols-2 lg:grid-cols-3">
        <div></div>
        <div
          className="grid gap-4 col-span-1 lg:col-span-2"
          style={{ gridTemplateColumns: `repeat(${visibleColumns.length}, minmax(0, 1fr))` }}
        >
          {visibleColumns.map(({ name }) => (
            <div key={name} className="border-b-2 border-white/20">{name}</div>
          ))}
        </div>
      </div>

      {rundown.cues?.map((cue, index) => {
        if (!cues[cue.id]) return null

        if (cues[cue.id]?.type !== CUE_TYPE_HEADING) {
          displayIndex++
        }

        if (cues[cue.id]?.type === CUE_TYPE_GROUP) return (
          <CueItemGroup
            key={index}
            index={displayIndex}
            cue={cues[cue.id]}
            groupChildren={cue.children}
            cues={cues}
            timestamps={timestamps}
            moment={moment}
            timezone={rundown?.timezone}
            cells={cells}
            visibleColumns={visibleColumns}
            todDisplayFormat={rundown.settings?.todDisplayFormat}
          />
        )
        if (cues[cue.id]?.type === CUE_TYPE_HEADING) return (
          <CueItemHeading
            key={index}
            cue={cues[cue.id]}
          />
        )

        return (
          <CueItemCue
            key={index}
            index={displayIndex}
            cue={cues[cue.id]}
            timestamp={timestamps.cues?.[cue.id]}
            moment={moment}
            timezone={rundown?.timezone}
            cells={cells[cue.id]}
            visibleColumns={visibleColumns}
            todDisplayFormat={rundown.settings?.todDisplayFormat}
          />
        )
      })}
    </div>
  )
}

RundownOutputCueList.propTypes = {
  cues: PropTypes.object.isRequired,
  timestamps: PropTypes.object.isRequired,
  moment: PropTypes.object,
  cells: PropTypes.object,
  visibleColumns: PropTypes.array,
}

function CueItemGroup ({ index, cue, groupChildren, cues, timestamps, moment, timezone, cells, visibleColumns, todDisplayFormat }) {
  const [searchParams] = useSearchParams()
  const configCueBackgroundColors = searchParams.get(OUTPUT_TYPES.CUE_BACKGROUND_COLORS) || OUTPUT_TYPES.ON

  const cueBackgroundColor = configCueBackgroundColors === OUTPUT_TYPES.ON ? cue.backgroundColor : 'transparent'

  let displayChildIndex = 0

  return (
    <>
      <div
        className="min-h-[80px] p-3 bg-gray-950 rounded-sm flex items-center gap-8 text-2xl"
        style={{
          backgroundColor: cueBackgroundColor || 'transparent',
        }}
      >
        <p className="w-[2rem]">{index}</p>
        <div>
          <p>{cue.title}</p>
        </div>
        <span className="grow"></span>
      </div>
      {Object.values(groupChildren).map((_cue) => {
        const cue = cues[_cue.id]

        if (cue.type === CUE_TYPE_HEADING) return (
          <CueItemHeading
            key={index}
            cue={cue}
          />
        )

        displayChildIndex++

        return (
          <CueItemCue
            key={`${index}_child_${displayChildIndex}`}
            index={`${index}.${displayChildIndex}`}
            cue={cue}
            timestamp={timestamps.cues?.[cue.id]}
            moment={moment}
            timezone={timezone}
            cells={cells[cue.id]}
            visibleColumns={visibleColumns}
            todDisplayFormat={todDisplayFormat}
          />
        )
      })}
    </>
  )
}

CueItemGroup.propTypes = {
  index: PropTypes.number.isRequired,
  cue: PropTypes.object.isRequired,
  groupChildren: PropTypes.array.isRequired,
  cues: PropTypes.object.isRequired,
  timestamps: PropTypes.object,
  moment: PropTypes.object,
  timezone: PropTypes.string,
  cells: PropTypes.object,
  visibleColumns: PropTypes.array,
  todDisplayFormat: PropTypes.string,
}

function CueItemHeading ({ cue }) {
  const [searchParams] = useSearchParams()
  const configCueBackgroundColors = searchParams.get(OUTPUT_TYPES.CUE_BACKGROUND_COLORS) || OUTPUT_TYPES.ON

  const cueBackgroundColor = configCueBackgroundColors === OUTPUT_TYPES.ON ? cue.backgroundColor : 'transparent'

  return (
    <>
      <div
        className="px-3 py-1 rounded-sm flex items-center gap-8 text-2xl"
        style={{
          backgroundColor: cueBackgroundColor || 'transparent',
        }}
      >
        <div>
          <p>{cue.title}</p>
        </div>
        <span className="grow"></span>
      </div>
    </>
  )
}

CueItemHeading.propTypes = {
  cue: PropTypes.object.isRequired,
}

function CueItemCue ({ index, cue, timestamp, moment, timezone, cells = {}, visibleColumns, todDisplayFormat }) {
  const [searchParams] = useSearchParams()
  const configStartTimes = searchParams.get(OUTPUT_TYPES.START_TIMES) || OUTPUT_TYPES.ON
  const configEndTimes = searchParams.get(OUTPUT_TYPES.END_TIMES) || OUTPUT_TYPES.ON
  const configCueBackgroundColors = searchParams.get(OUTPUT_TYPES.CUE_BACKGROUND_COLORS) || OUTPUT_TYPES.ON

  const cueBackgroundColor = configCueBackgroundColors === OUTPUT_TYPES.ON ? cue.backgroundColor : 'transparent'

  const isTypeCue = useMemo(() => cue.type === CUE_TYPE_CUE, [cue.type])
  const isActive = useMemo(() => isTypeCue && timestamp?.state === CueRunState.CUE_ACTIVE, [isTypeCue, timestamp?.state])
  const cueStyle = useMemo(() => isTypeCue
    ? {
        transition: 'ease 0.3s background-color',
        backgroundColor: isActive ? '#ef4444' : (!cueBackgroundColor || cueBackgroundColor === 'transparent' ? '#161616' : cueBackgroundColor),
        borderRadius: '0.25rem',
      }
    : {
        transition: 'ease 0.3s background-color',
        backgroundColor: (!cueBackgroundColor || cueBackgroundColor === 'transparent' ? 'transparent' : cueBackgroundColor),
        borderRadius: '0.25rem',
      }, [isTypeCue, isActive, cueBackgroundColor])

  if (!timestamp) return null

  return (
    <div className="relative rounded-sm grid grid-cols-2 lg:grid-cols-3 gap-4 text-2xl">
      {isActive ? <ScrollIntoView following={true} marginTop="0px" /> : ''}
      <div
        className={['flex items-center gap-8 p-3', visibleColumns.length === 0 ? 'col-span-full' : ''].join(' ')}
        style={cueStyle}
      >

        <p className="w-[2rem]">{index}</p>
        <div className="flex flex-col gap-2 w-[120px] min-w-[120px]">
          {configStartTimes !== OUTPUT_TYPES.OFF && (
            <p className="whitespace-nowrap font-mono text-left text-sm opacity-60 leading-3">
              {formatTimeOfDay(timestamp.actual.start, { timezone, seconds: 'nonzero', format: todDisplayFormat })}
            </p>
          )}
          <div className="font-mono whitespace-nowrap">
            {isActive
              ? <CueItemElapsed moment={moment} className="px-0!" overtimeClassName="px-1!" />
              : formatDurationHuman(timestamp.actual.duration)}
          </div>
          {configEndTimes !== OUTPUT_TYPES.OFF && (
            <p className="whitespace-nowrap font-mono text-left text-sm opacity-60 leading-3">
              {formatTimeOfDay(addMilliseconds(timestamp.actual.start, timestamp.actual.duration), { timezone, seconds: 'nonzero', format: todDisplayFormat })}
            </p>
          )}
        </div>
        <div>
          <p>{cue.title}</p>
          <p className="text-sm opacity-80">{cue.subtitle}</p>
        </div>
        <span className="grow"></span>
      </div>
      <div
        className={['grid gap-4 col-span-1 lg:col-span-2', visibleColumns.length === 0 ? 'hidden' : ''].join(' ')}
        style={{ gridTemplateColumns: `repeat(${visibleColumns.length}, minmax(0, 1fr))` }}
      >
        {visibleColumns && visibleColumns.map((column, index) => (
          <CueLoadingProvider
            key={index}
            cueId={cue.id}
            className="min-h-[80px]"
            style={cueStyle}
          >
            <CellContent cell={cells[column.id]} column={column} visibleColumnsCount={visibleColumns.length} />
          </CueLoadingProvider>
        ))}
      </div>
    </div>
  )
}

CueItemCue.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  cue: PropTypes.object,
  timestamp: PropTypes.object,
  moment: PropTypes.object,
  timezone: PropTypes.string,
  cells: PropTypes.object,
  visibleColumns: PropTypes.array,
  todDisplayFormat: PropTypes.string,
}

function CellContent ({ cell, column }) {
  const [eventEmitter] = useState(mitt())

  if (column?.type === CELL_SELECT) return (
    <div className="p-2">
      <div
        style={{
          backgroundColor: CELL_SELECT_COLORS[_indexOf(column.options, cell?.content.selected)],
        }}
        className={[
          'text-base rounded-sm px-3 py-1 text-center mx-auto',
        ].join(' ')}
        dangerouslySetInnerHTML={{ __html: cell?.content?.selected }}
      >
      </div>
    </div>
  )

  return (
    <CellItemText
      cell={cell}
      eventEmitter={eventEmitter}
      textSize="md"
      readonly
    />
  )
}

CellContent.propTypes = {
  cell: PropTypes.object,
  column: PropTypes.object,
  visibleColumnsCount: PropTypes.number,
}
