import PropTypes from 'prop-types'
import EditableHms from './EditableHms'
import { hmsToMilliseconds, millisecondsToHms } from '@rundown-studio/timeutils'
import { formatDurationHuman } from '../../../utils/formatTime.js'
import { CueRunState } from '@rundown-studio/utils'

export default function DurationPopoverContent ({
  duration = 0,
  setDuration,
  disabled = false,
  cueState = null,
  originalDuration = null,
  actualDuration = null,
}) {
  const hms = millisecondsToHms(Math.ceil(duration / 1000) * 1000)
  const onHmsUpdate = ({ hours, minutes, seconds }) => setDuration(hmsToMilliseconds({ hours, minutes, seconds }))

  // Generate description text based on provided props
  const formattedDuration = formatDuration({
    cueState,
    duration,
    originalDuration,
    actualDuration,
  })

  return (
    <div className="w-54 space-y-2">
      <h3 className="font-semibold text-sm">
        Cue Duration
      </h3>
      <EditableHms
        disabled={disabled}
        hms={hms}
        onUpdate={onHmsUpdate}
      />
      <p className="text-xs text-white/80">{formattedDuration}</p>
    </div>
  )
}

DurationPopoverContent.propTypes = {
  duration: PropTypes.number,
  setDuration: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  cueState: PropTypes.oneOf(Object.values(CueRunState)),
  originalDuration: PropTypes.number,
  actualDuration: PropTypes.number,
}

/**
 * Gets a human-readable description of a cue's duration
 */
function formatDuration ({ cueState, duration, originalDuration, actualDuration }) {
  const durationString = formatDurationHuman(duration)

  /* eslint no-fallthrough: "off" */
  switch (cueState) {
    case CueRunState.CUE_PAST: {
      const originalDurationString = formatDurationHuman(originalDuration)
      if (originalDuration === actualDuration) {
        return `Ran exactly ${originalDurationString}`
      }
      const difference = Math.abs(originalDuration - actualDuration - 1000)
      const diffDurationString = formatDurationHuman(difference)
      return `Ran ${diffDurationString} ${originalDuration < actualDuration ? 'over' : 'under'} the expected ${originalDurationString}.`
    }
    case CueRunState.CUE_ACTIVE:
    case CueRunState.CUE_NEXT:
    case CueRunState.CUE_FUTURE:
    default:
      return `Running for ${durationString}.`
  }
}
