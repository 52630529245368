import CueTimingDuration from './CueTimingDuration.jsx'
import CueTimingStartTime from './CueTimingStartTime.jsx'
import PropTypes from 'prop-types'
import { useState, useCallback } from 'react'
import { useAtomValue, useSetAtom } from 'jotai'
import { getTimestampByCueIdAtom } from '../../../store/timestamps.store.js'
import { firstCueIdAtom, getNextCueAtom, rundownAtom, setCueAtom } from '../../../store/rundown.store.js'
import { CueRunState, fastDeepEqual } from '@rundown-studio/utils'
import { momentAtom } from '../../../store/moment.store.js'
import { ACCESS_WRITE } from '../../../constants/rundownAccessStates.js'
import { RundownToken } from '../../../axios.js'
import _ from 'lodash'
import { CueStartMode } from '@rundown-studio/types'
import { updateRundownCue } from '../../../firestore.js'
import { applyDate } from '@rundown-studio/timeutils'
import { runnerStateAtom } from '../../../store/runner.store.js'
import { differenceInCalendarDays } from 'date-fns'
import { tz } from '@date-fns/tz'
import useDeepCompareMemo from '../../../utils/useDeepCompareMemo.js'

/**
 * CueTiming Component
 *
 * Displays and manages time-related information for a cue, including:
 * - Start time (with scheduling options)
 * - Duration
 * - Time variance indicators
 *
 * The component handles both display and editing of timing information,
 * synchronizing with Firestore for persistence.
 */
export default function CueTiming ({
  rundownId,
  cue,
  timezone,
  cellStyle,
}) {
  // Atoms and derived state
  const runnerState = useAtomValue(runnerStateAtom)
  const setCue = useSetAtom(setCueAtom)
  const rundown = useAtomValue(rundownAtom)
  const moment = useAtomValue(momentAtom)
  const getTimestampByCueId = useAtomValue(getTimestampByCueIdAtom)
  const timestamp = getTimestampByCueId(cue.id)
  const getNextCue = useAtomValue(getNextCueAtom)
  const nextCue = getNextCue(cue.id)
  const firstCueId = useAtomValue(firstCueIdAtom)
  const isFirstCue = cue.id === firstCueId
  const isSoftCue = cue.startMode === CueStartMode.FLEXIBLE

  // UI state
  const [loading, setLoading] = useState([])

  // Access and editing state
  const readonly = cue.locked || RundownToken.access !== ACCESS_WRITE || timestamp?.state === CueRunState.CUE_PAST

  // Extract only the needed timestamp properties to avoid unnecessary recalculations
  const timestampOriginalStart = timestamp?.original?.start
  const timestampActualStart = timestamp?.actual?.start

  /**
   * Calculate effective values that will be passed down.
   * This includes any derived or computed values.
   * - First cue startTime comes from rundown and is always FIXED
   *
   * Using useDeepCompareMemo for both preflight (dependency) and postflight (result) comparison
   * to avoid unnecessary reference changes when data is deeply equal.
   */
  const effectiveValues = useDeepCompareMemo(() => {
    const originalStart = timestampOriginalStart || new Date(cue.startTime)
    const actualStart = timestampActualStart || new Date(cue.startTime)

    // Start Time (Note: fallback for old cues without startTime)
    let startTime = cue.startTime ? applyDate(new Date(cue.startTime), originalStart, { timezone }) : originalStart
    if (isFirstCue) startTime = new Date(rundown.startTime)
    if (isSoftCue) startTime = actualStart

    // Start Mode
    let startMode = cue.startMode
    if (isFirstCue) startMode = CueStartMode.FIXED

    // Start Date Plus
    let startDatePlus = cue.startDatePlus
    if (isFirstCue) startDatePlus = 0
    if (isSoftCue) startDatePlus = differenceInCalendarDays(actualStart, rundown.startTime, { in: tz(timezone) })

    return {
      startTime,
      startMode,
      startDatePlus,
      scheduled: cue.scheduled,
      duration: cue.duration,
    }
  }, [
    isFirstCue,
    isSoftCue,
    rundown.startTime,
    cue.startTime,
    cue.startMode,
    cue.startDatePlus,
    cue.scheduled,
    cue.duration,
    timestampOriginalStart,
    timestampActualStart,
    timezone,
  ])

  /**
   * Updates the cue and (for first cue) the rundown
   * - Determine which properties are being updated
   * - Bail early: Skip API call if no actual changes
   * - Add all changed props to loading state
   * - Special handling for first cue
   */
  const handleUpdate = useCallback(async (update) => {
    // Determine which properties actually changed
    const changes = {}
    Object.entries(update).forEach(([key, value]) => {
      if (!fastDeepEqual(value, effectiveValues[key])) {
        changes[key] = value
      }
    })

    // Bail if no actual changes
    if (Object.keys(changes).length === 0) return

    // Track loading state for changed properties
    setLoading(Object.keys(changes))
    try {
      const { data } = await updateRundownCue(rundownId, cue.id, changes, isFirstCue)
      setCue(data)
    } catch (error) {
      console.error('Failed to update cue timing:', error)
      // Error handling is done by updateRundownCue (shows toast)
    } finally {
      setLoading([])
    }
  }, [isFirstCue, rundownId, cue.id, effectiveValues])

  return (
    <>
      {/* Start Time */}
      <CueTimingStartTime
        cueId={cue.id}
        values={effectiveValues}
        setValues={handleUpdate}
        readonly={readonly}
        loading={_.intersection(loading, ['startTime', 'startDatePlus', 'startMode', 'scheduled']).length > 0}
        timestamp={timestamp}
        moment={moment}
        timezone={timezone}
        rundown={rundown}
        isFirstCue={isFirstCue}
        cellStyle={cellStyle}
        timingProps={{
          todDisplayFormat: rundown?.settings?.todDisplayFormat,
          runnerState: runnerState,
          rundownStartTime: new Date(rundown.startTime),
        }}
        nextCue={nextCue}
      />

      {/* Duration */}
      <CueTimingDuration
        values={effectiveValues}
        setValues={handleUpdate}
        readonly={readonly}
        loading={_.includes(loading, 'duration')}
        timestamp={timestamp}
        moment={moment}
        timezone={timezone}
        cellStyle={cellStyle}
      />
    </>
  )
}

CueTiming.propTypes = {
  rundownId: PropTypes.string.isRequired,
  cue: PropTypes.object.isRequired,
  timezone: PropTypes.string,
  cellStyle: PropTypes.object,
}
