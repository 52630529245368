import { useCallback, useMemo, useRef, useState } from 'react'
import { useAtomValue, useSetAtom } from 'jotai'
import { setCueAtom, setCuesAtom, setCellsAtom, setCueOrderAtom, rundownAtom, firstCueIdAtom, getAutoScrollAtom, nextHardStartCueIdAtom, isCueInSoftSchedulePathAtom } from '../../store/rundown.store.js'
import PropTypes from 'prop-types'
import { CueLoadingProvider } from './CueLoadingProvider.jsx'
import CueCells from './CueCells.jsx'
import CueItemSettings from './CueItemSettings.jsx'
import CueItemIndex from './CueItemIndex.jsx'
import CueItemHeader from './CueItemHeader.jsx'
import CueItemTitle from './CueItemTitle.jsx'
import ScrollIntoView from './ScrollIntoView.jsx'
import TimingIndicator from './timing/TimingIndicator.jsx'
import FloatingCountdownButton from './timing/FloatingCountdownButton.jsx'
import { CUE_TYPE_CUE, CUE_TYPE_GROUP, CUE_TYPE_HEADING } from '../../constants/cueTypes.js'
import { addRundownCue, duplicateRundownCue, deleteRundownCue, updateRundownCue, updateRundown, updateRunner, startRunnerTimesnap, createRundownRunner } from '../../firestore.js'
import CueTiming from './timing/CueTiming.jsx'
import { runnerAtom, runnerStateAtom } from '../../store/runner.store.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import DropzoneIndicator from './cuelist/DropzoneIndicator.jsx'
import { RunnerState } from '@rundown-studio/types'
import { dragStartInterceptor } from '../../utils/dragStartInterceptor.js'

// KEEP ME - this empty array is used to ensure no re-renders between changes
const empty = []

export default function CueItem ({
  rundownId,
  index,
  className = '',
  runner,
  timezone,
  cue,
  cells = empty,
  columns = empty,
  isNext = { isNext },
  hiddenColumns,
  readonly,
  onDragStart = () => {},
  onDragOver = () => {},
  onDrop = () => {},
  dragging = false,
  dragover = false,
  setRundownColoursModalOpen,
  cueBackgroundColours,
  parentId = null,
  handleItemCollapse = () => {},
  collapsedGroups = empty,
  lastSibling = false,
  childrenCues = empty,
  cuesTypesMap,
  draggingGroup = false,
  parentBackgroundColour = '',
  autoAddGroup = false,
  currentCueHighlightColor = '',
}) {
  const [loading, setLoading] = useState(false)
  const [draggable, setDraggable] = useState(false)
  const setRundown = useSetAtom(rundownAtom)
  const setRunner = useSetAtom(runnerAtom)
  const runnerState = useAtomValue(runnerStateAtom)
  const setCueOrder = useSetAtom(setCueOrderAtom)
  const setCue = useSetAtom(setCueAtom)
  const setCues = useSetAtom(setCuesAtom)
  const setCells = useSetAtom(setCellsAtom)
  const firstCueId = useAtomValue(firstCueIdAtom)
  const nextHardStartCueId = useAtomValue(nextHardStartCueIdAtom)
  const autoScrollSettings = useAtomValue(getAutoScrollAtom)
  const isCueInSoftSchedulePath = useAtomValue(isCueInSoftSchedulePathAtom)
  const isAutoScrollEnabled = autoScrollSettings[rundownId] !== false

  const isFirstCue = cue.id === firstCueId
  const isNextHardStart = cue.id === nextHardStartCueId

  const cueWrapper = useRef()

  const groupLineColour = (parentBackgroundColour && parentBackgroundColour !== 'transparent') ? parentBackgroundColour : null

  const isTypeCue = useMemo(() => cue.type === CUE_TYPE_CUE, [cue.type])
  const isActive = useMemo(() => cue.id === runner?.timesnap?.cueId)
  const cellStyle = useMemo(() => {
    const userBgColor = cue.backgroundColor === 'transparent' ? undefined : cue.backgroundColor
    // When the user sets a currentCueHighlightColor in the rundown settings, apply that colour to an active cue
    const activeColor = isActive ? (currentCueHighlightColor || userBgColor) : undefined
    const backgroundColor = isTypeCue
      ? (activeColor || userBgColor || '#161616')
      : (userBgColor || 'black')
    return {
      transition: 'ease 0.3s background-color',
      backgroundColor,
      borderRadius: '0.25rem',
    }
  }, [isTypeCue, isActive, cue.backgroundColor, currentCueHighlightColor])

  const scrollIntoView = useCallback(() => {
    cueWrapper?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [cueWrapper])

  async function setNextCue () {
    setLoading(true)
    let nextCueId = cue.id
    if (cuesTypesMap[cue.id] === CUE_TYPE_GROUP) {
      nextCueId = childrenCues.filter((c) => c.type === CUE_TYPE_CUE)[0]?.id
    }
    if (!nextCueId) return setLoading(false)
    if (runnerState === RunnerState.PRESHOW) {
      return updateRundown(rundownId, { startCueId: nextCueId })
        .then(({ data }) => setRundown(data))
        .finally(() => setLoading(false))
    } else {
      return updateRunner(rundownId, runner.id, { nextCueId })
        .then(({ data }) => setRunner(data))
        .finally(() => setLoading(false))
    }
  }

  async function jumpToCue () {
    setLoading(true)
    let nextCueId = cue.id
    if (cuesTypesMap[cue.id] === CUE_TYPE_GROUP) {
      nextCueId = childrenCues.filter((c) => c.type === CUE_TYPE_CUE)[0]?.id
    }
    if (!nextCueId) return setLoading(false)
    if (runner) {
      return await startRunnerTimesnap(rundownId, runner.id, nextCueId)
        .then(({ data }) => setRunner(data))
        .finally(() => setLoading(false))
    } else {
      return updateRundown(rundownId, { startCueId: nextCueId })
        .then(({ data }) => setRundown(data))
        .then(() => createRundownRunner(rundownId))
        .finally(() => setLoading(false))
    }
  }

  async function updateCue (payload) {
    setLoading(true)
    return updateRundownCue(rundownId, cue.id, payload, isFirstCue)
      .then(({ data }) => setCue(data))
      .finally(() => setLoading(false))
  }

  async function addCue ({ position = 'after', payload } = {}) {
    setLoading(true)
    return addRundownCue(rundownId, { [position]: cue.id }, payload)
      .then(({ data: [rundown, cues] }) => {
        setCueOrder(rundown.cues)
        setCues(cues)
      })
      .finally(() => setLoading(false))
  }

  async function duplicateCue () {
    setLoading(true)
    return duplicateRundownCue(rundownId, cue.id)
      .then(({ data: { rundown, cues, cells } }) => {
        setCueOrder(rundown.cues)
        setCues(cues)
        setCells(cells)
      })
      .finally(() => setLoading(false))
  }

  async function deleteCue () {
    setLoading(true)
    return deleteRundownCue(rundownId, cue.id)
      .finally(() => setLoading(false))
  }

  function toggleGroupCollapse () {
    handleItemCollapse(cue.id)
  }

  const cueCellsMemo = useMemo(() => (
    <CueLoadingProvider
      cueId={cue.id}
      className="flex items-stretch gap-1"
    >
      <CueCells
        cue={cue}
        cells={cells}
        columns={columns}
        hiddenColumns={hiddenColumns}
        cellStyle={cellStyle}
        readonly={readonly}
      />
    </CueLoadingProvider>
  ), [cue?.id, cells, columns, hiddenColumns?.length, readonly, cellStyle])

  return (
    <section
      ref={cueWrapper}
      id={cue.id}
      className={[
        'relative px-2 transition-[margin,opacity]',
        (dragging ? 'opacity-50' : ''),
        (isFirstCue ? 'pt-2' : ''),
        (isNextHardStart ? 'scroll-m-24' : ''),
        className,
      ].join(' ')}
      data-dropzone
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {isActive ? <ScrollIntoView following={isAutoScrollEnabled} marginTop="-90px" /> : ''}
      {/* Dropzone Indicator */}
      {!draggingGroup && (
        <div className="absolute top-px left-[2.5rem] w-4/5">
          <DropzoneIndicator
            className={parentId ? 'ml-12' : 'ml-0'}
            visible={dragover && cue.id !== autoAddGroup}
          />
        </div>
      )}

      {/* Line connecting scheduled soft cues */}
      {isCueInSoftSchedulePath(cue.id) && (
        // Emulate start time block left margin: 2 + 7 + 1 + 12 + 1 units
        <div className="absolute left-23 w-28">
          <div className="absolute left-1/2 -ml-[0.5px] w-px h-12 bg-white" />
        </div>
      )}

      {/* Active cue line above cue */}
      {isActive
      && (
        <div className="relative">
          <span className={[
            isFirstCue ? 'mb-4' : '',
            'mt-4 w-full h-1 block bg-[#ef4444] outline-4 outline-black',
          ].join(' ')}
          >
          </span>
          <span className="absolute -top-[6px] ml-8 text-xs font-bold uppercase text-[#ef4444] bg-black px-2">Current cue</span>
        </div>
      )}

      {/* Timing Indicator (e.g. "Gap of 5 minutes") */}
      {!isFirstCue ? (
        <div className="min-h-3 ml-[84px] my-0.5 w-fit">
          <TimingIndicator
            cue={cue}
            timezone={timezone}
          />
        </div>
      ) : null}

      {/* Floating Bottom Bar indicating countdown to next hard start */}
      {(isNextHardStart && !isFirstCue) ? (
        <FloatingCountdownButton
          cue={cue}
          timezone={timezone}
          onScrollTo={scrollIntoView}
        />
      ) : null}

      {/* Next cue line above cue */}
      {isNext
      && (
        <div className="relative">
          <span className={[
            'mt-1 mb-4 w-full h-[2px] block bg-gray-400 outline-4 outline-black',
          ].join(' ')}
          >
          </span>
          <span className="absolute -top-[6px] ml-8 text-xs font-bold uppercase text-gray-300 bg-black px-2">Next cue</span>
        </div>
      )}
      {/* Group tree lines */}
      {(parentId || cue.type === CUE_TYPE_GROUP)
      && (
        <>
          {/* Top */}
          <div
            style={{
              backgroundColor: groupLineColour,
            }}
            className={[
              'w-[4px] absolute left-[20px] -z-10 -top-6',
              groupLineColour ? 'brightness-150' : 'bg-gray-700',
              [CUE_TYPE_CUE, CUE_TYPE_HEADING].includes(cue.type) ? 'opacity-100' : 'opacity-0',
              lastSibling ? 'h-[42%] top-0 ' : 'h-[80%] -top-6',
            ].join(' ')}
          >
          </div>
          {/* Middle curve */}
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              color: groupLineColour,
            }}
            className={[
              'absolute top-[41%] left-[20px] pointer-events-none',
              groupLineColour ? 'brightness-150' : 'text-gray-700',
              [CUE_TYPE_CUE, CUE_TYPE_HEADING].includes(cue.type) ? 'opacity-100' : 'opacity-0',
            ].join(' ')}
          >
            <path d="M2 0C2 10 15 10 15 10" stroke="currentColor" strokeWidth={4} />
          </svg>

          {/* Bottom */}
          <div
            style={{
              backgroundColor: groupLineColour,
            }}
            className={[
              'h-1/2 w-[4px] absolute left-[20px]',
              groupLineColour ? 'brightness-150' : 'bg-gray-700',
              [CUE_TYPE_CUE, CUE_TYPE_HEADING].includes(cue.type) ? 'bottom-0' : '-bottom-10',
              collapsedGroups.includes(cue.id) || lastSibling || (cue.type === CUE_TYPE_GROUP && !childrenCues.length) ? 'opacity-0' : 'opacity-100',
            ].join(' ')}
          >
          </div>
        </>
      )}

      {/* Cue Item */}
      <div
        className={[
          'relative group flex items-stretch min-h-[84px] gap-1 w-full',
        ].join(' ')}
        draggable={draggable && !readonly}
        onDragStart={dragStartInterceptor.bind(null, onDragStart)}
        data-label={`cue-${cue.id}`}
      >
        {/**
          * Controls
          *
          * This fixes the conflicts of `draggable` with `contenteditable` and <input> fields by only
          * activating the draggable attribute if the mouse if over the draghandle area.
          */}
        <div
          className="w-7 flex-none"
          onMouseEnter={setDraggable.bind(null, true)}
          onMouseLeave={setDraggable.bind(null, false)}
        >
          <CueItemSettings
            cue={cue}
            readonly={readonly}
            onJumpToCue={jumpToCue}
            onSetNext={setNextCue}
            onAddCue={addCue}
            onDuplicateCue={duplicateCue}
            onUpdateCue={updateCue}
            onDeleteCue={deleteCue}
            parentId={parentId}
            setRundownColoursModalOpen={setRundownColoursModalOpen}
            cueBackgroundColours={cueBackgroundColours}
          />
        </div>
        {/* Index */}
        {[CUE_TYPE_CUE, CUE_TYPE_GROUP].includes(cue.type) && (
          <div
            className="w-12 flex-none flex"
            style={{
              ...cellStyle,
              backgroundColor: isActive ? '#ef4444' : (cue.backgroundColor === 'transparent' && cue.type === CUE_TYPE_CUE ? '#161616' : cue.backgroundColor || '#161616'),
            }}
          >
            <CueItemIndex
              cueId={cue.id}
              className={parentId ? 'text-sm font-semibold' : 'text-base font-semibold'}
              index={index}
              type={cue.type}
              readonly={readonly || cue.type === CUE_TYPE_HEADING}
              loading={loading}
              onSetNext={setNextCue}
              onJumpToCue={jumpToCue}
            />
          </div>
        )}
        {isTypeCue && (
          <CueTiming
            rundownId={rundownId}
            cue={cue}
            timezone={timezone || 'UTC'}
            cellStyle={cellStyle}
          />
        )}
        {/* Title / Heading */}
        <div
          className={[
            'flex-none ring-2 rounded-sm transition-all',
            cue.type === CUE_TYPE_CUE ? 'w-[326px]' : '',
            cue.type === CUE_TYPE_GROUP ? 'w-[558px]' : '',
            cue.type === CUE_TYPE_HEADING ? 'w-[610px]' : '',
            cue.id === autoAddGroup ? 'ring-blue-500' : 'ring-transparent',
          ].join(' ')}
          style={cellStyle}
        >
          {isTypeCue ? (
            <CueItemHeader
              cue={cue}
              onUpdateCue={updateCue}
              readonly={readonly}
            />
          ) : (
            <div className="flex h-full">
              <CueItemTitle
                className="font-semibold"
                cue={cue}
                onUpdateCue={updateCue}
                readonly={readonly || cue.locked}
                collapsed={collapsedGroups.includes(cue.id)}
                childrenCount={childrenCues.length}
              />
              {
                // Toggle (chevron) group collapse
                cue.type === CUE_TYPE_GROUP && (
                  <div className="flex flex-col justify-center mr-2">
                    <button
                      className={[
                        'flex-none w-7 h-7 px-2 rounded-sm opacity-70 text-white hover:opacity-100! transition-opacity text-xs focus:outline-hidden',
                      ].join(' ')}
                      onClick={toggleGroupCollapse}
                    >
                      <FontAwesomeIcon icon={faChevronDown} className={['transition-all', collapsedGroups?.includes(cue.id) ? 'rotate-90' : ''].join(' ')} />
                    </button>
                  </div>
                )
              }
            </div>
          )}
        </div>
        {/* Cells */}
        {[CUE_TYPE_CUE].includes(cue.type)
          ? (
              cueCellsMemo
            )
          : null}
      </div>
      {/* End Time */}
    </section>
  )
}

CueItem.propTypes = {
  rundownId: PropTypes.string.isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  runner: PropTypes.object,
  timezone: PropTypes.string,
  cue: PropTypes.object.isRequired,
  cells: PropTypes.array,
  columns: PropTypes.array.isRequired,
  hiddenColumns: PropTypes.array.isRequired,
  isNext: PropTypes.bool,
  readonly: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onDragOver: PropTypes.func,
  dragging: PropTypes.bool,
  dragover: PropTypes.bool,
  setRundownColoursModalOpen: PropTypes.func,
  cueBackgroundColours: PropTypes.array,
  parentId: PropTypes.string,
  handleItemCollapse: PropTypes.func,
  collapsedGroups: PropTypes.array,
  lastSibling: PropTypes.bool,
  childrenCues: PropTypes.array,
  cuesTypesMap: PropTypes.object,
  draggingGroup: PropTypes.bool,
  parentBackgroundColour: PropTypes.string,
  autoAddGroup: PropTypes.string,
  running: PropTypes.bool,
  currentCueHighlightColor: PropTypes.string,
}
