import { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { getEvent, getEventRundownLinks, getEventRundowns } from '../firestore'
import { useParams } from 'react-router-dom'
import RundownCard from '../components/dashboard/partials/RundownCard'

export default function PublicEvent() {
  const { eventId } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [event, setEvent] = useState(null)
  const [rundowns, setRundowns] = useState(null)
  const [rundownLinks, setRundownLinks] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const searchParams = new URLSearchParams(window.location.search)
        const params = {
          'signature': searchParams.get('signature'),
          'full-access': searchParams.get('full-access'),
        }
        const [
          eventResponse,
          rundownsResponse,
          rundownLinksResponse,
        ] = await Promise.all([
          getEvent(eventId, params),
          getEventRundowns(eventId, params),
          getEventRundownLinks(eventId, { ...params, back: window.location.href }),
        ])
        if (eventResponse.data.archivedAt) setError({ message: 'This event is archived.' })
        setEvent(eventResponse.data)
        setRundowns(rundownsResponse.data.sort((a, b) => new Date(a.startTime) - new Date(b.startTime)))
        setRundownLinks(rundownLinksResponse.data)
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [eventId])

  if (error) {
    return (
      <Layout title={event?.name} logoUrl={event?.logo} branding={true}>
        <p>{error.message}</p>
      </Layout>
    )
  }

  return (
    <Layout title={event?.name} loading={loading} logoUrl={event?.logo} branding={true}>
      <>
        {
          rundowns && rundownLinks && rundowns.filter((r) => !r.archivedAt).map((rundown) => {
            return (
              <RundownCard
                key={rundown.id}
                rundown={rundown}
                overwriteLink={rundownLinks[rundown.id]}
                disabled={true}
                small={true}
              />
            )
          })
        }
        { rundowns?.length === 0 && <p className="text-gray-400 text-center">No rundowns have been created for this event.</p>}
      </>
    </Layout>
  )
}
