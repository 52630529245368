import { useState, useEffect } from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import RundownNavbar from '../../components/rundown/RundownNavbar.jsx'
import RundownHeader from '../../components/rundown/header/RundownHeader.jsx'
import RundownBody from '../../components/rundown/RundownBody.jsx'
import { ACCESS_WRITE } from '../../constants/rundownAccessStates.js'
import { RundownToken } from '../../axios.js'
import { Helmet } from 'react-helmet'

export default function RundownMain () {
  const {
    rundown,
    runner,
    cues,
    cells,
    columns,
    plan,
    event,
  } = useOutletContext()
  const [hiddenColumns, setHiddenColumns] = useState([])
  const [searchParams] = useSearchParams()
  const writeAccess = RundownToken.access === ACCESS_WRITE

  useEffect(() => {
    let colIds = searchParams.has('hiddenColumns') ? searchParams.get('hiddenColumns').split(',').filter(Boolean) : []
    setHiddenColumns(colIds)
  }, [searchParams])

  return (
    <div data-label="layout-rundown">
      <Helmet>
        <title>
          {rundown?.name} | Rundown Studio
        </title>
      </Helmet>
      <div className="relative h-screen min-w-screen w-max flex flex-col text-white">
        <div
          className="w-screen px-2"
          style={{ height: '50px' }}
        >
          <RundownNavbar
            rundown={rundown}
            event={event}
            backUrl={searchParams.get('back')}
          />
        </div>
        <div
          id="rd-scroll-container"
          className="relative w-screen overflow-scroll"
          style={{ height: 'calc(100vh - 50px)' }}
        >
          <header className="h-[100px] sticky top-0 z-99">
            <RundownHeader
              rundown={rundown}
              runner={runner}
              cues={cues}
              columns={columns}
              hiddenColumns={hiddenColumns}
              plan={plan}
              readonly={!writeAccess}
            />
          </header>
          <main className="">
            <RundownBody
              rundown={rundown}
              cues={cues}
              cells={cells}
              columns={columns}
              runner={runner}
              hiddenColumns={hiddenColumns}
              plan={plan}
              readonly={!writeAccess}
            />
          </main>
        </div>
      </div>
    </div>
  )
}

// RundownMain.propTypes = {
//   rundown: PropTypes.object.isRequired,
//   runner: PropTypes.object.isRequired,
//   cues: PropTypes.array.isRequired,
//   cells: PropTypes.array.isRequired,
//   columns: PropTypes.array.isRequired,
//   setRunner: PropTypes.func.isRequired,
// }
