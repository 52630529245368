import { format } from 'date-fns'
import millisecondsToDhms from './millisecondsToDhms.js'
import _padStart from 'lodash/padStart'

export function formatDuration (milliseconds) {
  const zero = new Date().setHours(0, 0, 0, 0)

  const stringFormat = milliseconds > 3599999 || milliseconds < -3599999 ? 'hh:mm:ss' : 'mm:ss'

  return format(zero + Math.abs(milliseconds), stringFormat)
}

export function formatCountdown (milliseconds, overtimePrefix = '') {
  const dhms = millisecondsToDhms(milliseconds, { ceil: true })
  const toDigit = (num, digits) => _padStart(num, digits, '0')
  let output = toDigit(dhms.minutes, 2) + ':' + toDigit(dhms.seconds, 2)
  if (dhms.days) output = dhms.days + ':' + toDigit(dhms.hours, 2) + ':' + output
  else if (dhms.hours) output = dhms.hours + ':' + output
  const isOver = milliseconds < 0
  return (isOver ? overtimePrefix : '') + output
}

/**
 * Formats milliseconds into a human-readable duration string.
 *
 * For positive values (countdown):
 * - 0ms: "0s"
 * - 1-59 seconds: "1s" through "59s"
 * - 1-59 minutes: "1m 0s" through "59m 59s" (can omit "0s" with omitZero option)
 * - 1+ hours: "1h 0m 0s" through "Xh Ym Zs" (can omit zeros with omitZero option)
 *
 * For negative values (overtime):
 * - 0 to -999ms: "0s" (holds at zero)
 * - -1000ms and beyond: "+1s" through "+Xh Ym Zs" (with same zero handling)
 *
 * @param {number} milliseconds - Duration in milliseconds
 * @param {Object} [options] - Formatting options
 * @param {string} [options.prefix='+'] - Prefix to use for overtime values
 * @param {boolean} [options.omitZero=false] - Whether to omit components that are zero
 * @returns {string} - Formatted duration string
 */
export function formatDurationHuman (milliseconds, { prefix = '+', omitZero = false } = {}) {
  // Handle all cases that should return "0s"
  const isNonFinite = !Number.isFinite(milliseconds)
  const isExactlyZero = milliseconds === 0
  const isNearZeroNegative = milliseconds < 0 && milliseconds > -1000

  if (isNonFinite || isExactlyZero || isNearZeroNegative) {
    return '0s'
  }

  // Determine if we're in overtime (negative milliseconds beyond -999)
  const isOvertime = milliseconds <= -1000

  // Round milliseconds appropriately
  const roundedMs = Math.abs(Math.ceil(milliseconds / 1000) * 1000)

  // Calculate time components
  const hours = Math.floor(roundedMs / 3600000)
  const minutes = Math.floor((roundedMs % 3600000) / 60000)
  const seconds = Math.floor((roundedMs % 60000) / 1000)

  // Format a time unit, conditionally omitting zeros
  const formatUnit = (value, unit) => {
    if (value === 0 && omitZero) return ''
    return ` ${value}${unit}`
  }

  // Build the formatted string based on magnitude
  let result = ''

  if (hours > 0) {
    result = `${hours}h${formatUnit(minutes, 'm')}${formatUnit(seconds, 's')}`
  } else if (minutes > 0) {
    result = `${minutes}m${formatUnit(seconds, 's')}`
  } else {
    result = `${seconds}s`
  }

  // Trim the result (in case we have no trailing units)
  result = result.trim()

  // Add the "+" prefix for overtime values
  return isOvertime ? (prefix + result) : result
}

/**
 * Foramt time of day using browser language
 * @param  {Date} date
 * @param  {boolean} [options.secondsOptional=false]
 * @param  {boolean} [options.hour12]
 * @param  {string} [options.timezone]
 * @param  {boolean} [options.omitMinutes]
 * @return {string}
 */
export function formatTimeOfDay (date, {
  secondsOptional = false,
  hour12 = undefined,
  timezone = undefined,
  omitMinutes = false,
} = {}) {
  if (!date) return ''
  const options = {}
  const hasSeconds = date.getSeconds() !== 0
  options.timeStyle = secondsOptional && !hasSeconds ? 'short' : 'medium'
  if (hour12 !== undefined) options.hour12 = hour12
  if (timezone) options.timeZone = timezone
  if (omitMinutes) options.timeStyle = 'short'
  const language = !navigator.language || navigator.language === 'C' ? 'en-US' : navigator.language
  return new Intl.DateTimeFormat(language, options).format(date)
}

/**
 * Format calendar date using the browser language
 * @param  {Date} date
 * @param  {string} [timezone]
 * @return {string}
 */
export function formatCalendarDate (date, timezone = undefined) {
  if (!date) return ''
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  if (timezone) options.timeZone = timezone
  return new Intl.DateTimeFormat(navigator.language, options).format(date)
}

export function millisecondsToHms (ms = 0) {
  return {
    hours: Math.floor(ms / 3600000) || 0,
    minutes: Math.floor((ms % 3600000) / 60000) || 0,
    seconds: Math.floor((ms % 60000) / 1000) || 0,
    decimals: Math.floor((ms % 1000) / 100) || 0,
  }
}

export function hmsToMilliseconds ({ hours = 0, minutes = 0, seconds = 0 } = {}) {
  return hours * 3600000 + minutes * 60000 + seconds * 1000
}

export function formatString (date) {
  if (!date) return ''
  const formatStr = date.getSeconds() !== 0 ? 'HH:mm:ss' : 'HH:mm'
  return format(date, formatStr)
}

/**
 * Round down a time, given in milliseconds, to the nearest second
 * @param  {number} ms [a timestamp represented as milliseconds]
 * @return {number}
 */
export function floorSeconds (ms) {
  return Math.floor(ms / 1000) * 1000
}

export function isBrowser24h () {
  const formattedTime = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).format(0)
  return !(/AM|PM/i.test(formattedTime))
}
