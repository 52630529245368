import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../interactives/Tooltip.jsx'
import { CueStartMode } from '@rundown-studio/types'
import formatTimeOfDay from '../../../utils/formatTimeOfDay.js'
import { useMemo } from 'react'

/**
 * StartTimeSupDisplay Component
 *
 * sup = above content
 *
 * Displays a toggle button for controlling cue scheduling (auto-advance or fixed time)
 * in the top section of a cue timing cell.
 */
export default function StartTimeSupDisplay({
  startTime,
  startMode = CueStartMode.FLEXIBLE,
  scheduled,
  setScheduled,
  readonly = false,
  timezone,
  todDisplayFormat,
}) {
  const isTimeInPast = startMode === CueStartMode.FIXED && startTime && startTime < new Date()
  const isButtonDisabled = readonly || (isTimeInPast && !scheduled)
  const isPulsating = startMode === CueStartMode.FIXED && scheduled && startTime && startTime > new Date()

  const tooltipText = useMemo(() => {
    if (startMode === CueStartMode.FLEXIBLE) {
      if (scheduled && readonly) return 'Cue auto-starts after previous cue'
      return scheduled
        ? 'Disable auto-start after previous cue'
        : 'Enable auto-start after previous cue'
    } else {
      // Fixed mode
      if (isTimeInPast && !scheduled) {
        return 'Cannot enable auto-start, time is in the past. Adjust rundown date or cue time.'
      }
      const dateStr = startTime.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      })
      const timeStr = formatTimeOfDay(startTime, {
        timezone,
        seconds: 'nonzero',
        format: todDisplayFormat,
      })
      const timeStatus = isTimeInPast ? '(past)' : '(future)'
      if (scheduled && readonly) return `Auto-starts at ${timeStr} on ${dateStr} ${timeStatus}`
      return scheduled
        ? `Disable auto-start at ${timeStr} on ${dateStr} ${timeStatus}`
        : `Enable auto-start at ${timeStr} on ${dateStr} ${timeStatus}`
    }
  }, [isTimeInPast, startMode, startTime, scheduled, todDisplayFormat])

  if (readonly && !scheduled) return null

  return (
    <Tooltip>
      <TooltipTrigger>
        <button
          className={[
            'relative block w-full h-5 text-center text-xs leading-0 rounded-t transition-colors bg-white/0 text-white',
            (isButtonDisabled ? 'cursor-default!' : 'hover:bg-white/10'),
          ].join(' ')}
          onClick={() => setScheduled(!scheduled)}
          disabled={isButtonDisabled}
        >
          <div
            className={[
              (scheduled ? 'opacity-100' : 'opacity-0 group-hover:opacity-40'),
              (isPulsating ? 'animate-pulse' : ''),
            ].join(' ')}
          >
            {startMode === CueStartMode.FLEXIBLE ? (
              <>
                <div className="absolute -top-1 left-1/2 -ml-[0.5px] w-px h-[15px] rounded bg-current" />
                <FontAwesomeIcon icon={faAngleDown} size="xs" />
              </>
            ) : (
              <>
                <div className="absolute top-[3px] left-1/2 -ml-[6px] w-[12px] h-px rounded bg-current" />
                <div className="absolute top-[3px] left-1/2 -ml-[0.5px] w-px h-[8px] rounded bg-current" />
                <FontAwesomeIcon icon={faAngleDown} size="xs" />
              </>
            )}
          </div>
        </button>
      </TooltipTrigger>
      <TooltipContent>
        <span className="text-xs">
          {tooltipText}
        </span>
      </TooltipContent>
    </Tooltip>
  )
}

StartTimeSupDisplay.propTypes = {
  startTime: PropTypes.instanceOf(Date),
  startMode: PropTypes.oneOf(Object.values(CueStartMode)),
  scheduled: PropTypes.bool,
  setScheduled: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  timezone: PropTypes.string,
  todDisplayFormat: PropTypes.string,
}
