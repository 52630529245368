import { getCellDefaults, getColumnDefaults, getCueDefaults, getRundownDefaults } from '@rundown-studio/types'
import { parseSnapshot } from '@rundown-studio/utils'

/**
 * Rundown specific wrapper for parseSnapshot
 */
export function parseRundownSnapshot (snapshot) {
  const defaults = getRundownDefaults()
  const dateKeys = ['startTime', 'endTime', 'archivedAt', 'deletedAt']
  return parseSnapshot(snapshot, { defaults, dateKeys })
}

/**
 * Cue specific wrapper for parseSnapshot
 */
export function parseCueSnapshot (snapshot) {
  const defaults = getCueDefaults()
  const dateKeys = ['startTime']
  return parseSnapshot(snapshot, { defaults, dateKeys })
}

/**
 * Cell specific wrapper for parseSnapshot
 */
export function parseCellSnapshot (snapshot) {
  const defaults = getCellDefaults()
  const dateKeys = []
  return parseSnapshot(snapshot, { defaults, dateKeys })
}

/**
 * Column specific wrapper for parseSnapshot
 */
export function parseColumnSnapshot (snapshot) {
  const defaults = getColumnDefaults()
  const dateKeys = []
  return parseSnapshot(snapshot, { defaults, dateKeys })
}
