/**
 * Intercepts the drag start event and only propagates it if the mouse is over an element with `data-draghandle`.
 *
 * @param {Function} onDragStartFunction - The function to execute if the drag event is valid.
 * @param {DragEvent} event - The drag event.
 * @returns {void}
 */
export function dragStartInterceptor (onDragStartFunction, event) {
  const handles = Array.from(event.target.querySelectorAll('[data-draghandle]'))
  if (!handles.some((target) => _mouseInside(target, event))) return event.preventDefault()
  onDragStartFunction(event)
}

function _mouseInside (target, event) {
  var rect = target.getBoundingClientRect()
  if (event.clientX <= rect.left || rect.right < event.clientX) return false
  if (event.clientY <= rect.top || rect.bottom < event.clientY) return false
  return true
}
