import PropTypes from 'prop-types'
import { formatTimeOfDay } from '@rundown-studio/timeutils'
import { useMemo } from 'react'
import floorMs from '../../../utils/floorMs.js'

/**
 * StartTimeTopDisplay Component
 *
 * Displays the original/planned start time along with visual indicators
 * for hard start and auto-start modes.
 */
export default function StartTimeTopDisplay ({
  timestamp,
  timezone,
  todDisplayFormat,
  cellStyle,
}) {
  // Generate formatted time text internally based on timestamp
  const timeText = useMemo(() => {
    if (!timestamp) return ''

    // Only show original time if it differs from actual
    const originalTime = floorMs(timestamp.original.start)
    const actualTime = floorMs(timestamp.actual.start)

    if (originalTime.getTime() !== actualTime.getTime()) {
      return formatTimeOfDay(timestamp.original.start, {
        timezone,
        seconds: 'nonzero',
        format: todDisplayFormat,
      })
    }

    return ''
  }, [timestamp, timezone, todDisplayFormat])

  // If we have no time to display and no indicators to show, return null
  if (!timeText) return null

  return (
    <div
      className="h-4 text-xs text-white/60 tabular-nums space-x-2 whitespace-nowrap truncate px-1"
      style={cellStyle}
    >
      <span className="line-through">{timeText}</span>
    </div>
  )
}

StartTimeTopDisplay.propTypes = {
  timestamp: PropTypes.object,
  timezone: PropTypes.string,
  todDisplayFormat: PropTypes.string,
  cellStyle: PropTypes.object,
}
