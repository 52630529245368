import PropTypes from 'prop-types'
import { applyDate, formatCalendarDate, getTimezoneOffset } from '@rundown-studio/timeutils'
import { useEffect, useState } from 'react'
import isValidDate from '../../utils/isValidDate'

export default function DateInput ({
  className = '',
  date,
  timezone,
  updateDate,
  loading,
  disabled,
  ...props
}) {
  const [internalDate, setInternalDate] = useState(date)

  useEffect(() => setInternalDate(date), [date])

  function onChange(event) {
    const internalDate = new Date(event.target.value)
    if (!isValidDate(internalDate)) return

    // Adjust to the target timezone
    const targetOffset = getTimezoneOffset(timezone || 'UTC', internalDate) // Offset in milliseconds
    const adjustedDate = new Date(internalDate.getTime() - targetOffset)

    setInternalDate(adjustedDate)
    updateDate(adjustedDate)
  }

  function onBlur () {
    const newDate = applyDate(date, internalDate, { timezone })
    if (date.getTime() != newDate.getTime()) {
      updateDate(newDate)
    }
  }

  const formattedDate = formatCalendarDate(new Date(internalDate), { timezone, format: 'yyyy-MM-dd' })

  return (
    <input
      className={[
        'enabled:cursor-text transition-colors px-2 h-6 text-gray-300 rounded-xs font-mono bg-transparent text-left focus:outline-hidden',
        'enabled:hover:brightness-125 enabled:hover:bg-white/20',
        loading ? 'bg-animated from-transparent to-white/20 pointer-events-none' : '',
        className,
      ].join(' ')}
      type="date"
      value={formattedDate}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled || loading}
      {...props}
    />
  )
}

DateInput.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]).isRequired,
  timezone: PropTypes.string,
  updateDate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}
