/**
 * SavedStateIndicator Component
 *
 * A reusable component that displays saving states (saving, saved, error) with automatic timeouts.
 *
 * This component can be used in three different ways:
 * 1. Simple prop approach - Pass `saving={true/false}` and let the component handle state transitions
 * 2. Ref method approach - Create a ref and access methods like `ref.current.savingStart()`
 * 3. Hook approach - Use the `useSavedState()` hook for the simplest API (recommended)
 *
 * @typedef {Object} SavedStateIndicatorProps
 * @property {boolean} saving - Simple toggle for saving state (if undefined, component uses manual control)
 * @property {string} className - Additional CSS classes for positioning
 * @property {string} failedLabel - Custom error message to display
 * @property {string} savingLabel - Custom text for saving state (default: "Saving...")
 * @property {string} successLabel - Custom text for success state (default: "Saved")
 * @property {number} timeout - How long to show saved/error state in milliseconds
 */

import PropTypes from 'prop-types'
import { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

/**
 * SAVED_STATE enum defines the possible states of the indicator
 *
 * - INITIAL: No indicator shown
 * - SAVING: Shows spinner with "Saving..." text
 * - SAVED: Shows checkmark with "Saved" text
 * - ERROR: Shows error icon with error message
 */
export const SAVED_STATE = {
  INITIAL: 'INITIAL',
  SAVING: 'SAVING',
  SAVED: 'SAVED',
  ERROR: 'ERROR',
}

/**
 * SavedStateIndicator component implements a reusable indicator for save operations
 */
const SavedStateIndicator = forwardRef(function SavedStateIndicatorRef ({
  saving,
  className = '',
  savingLabel = 'Saving…',
  successLabel = 'Saved',
  failedLabel = 'Failed',
  timeout = 2000,
}, ref) {
  const [state, setState] = useState(SAVED_STATE.INITIAL)
  const timeoutRef = useRef(null)
  const isUsingPropControl = useRef(saving !== undefined)

  /**
   * Clears any existing timeout to prevent memory leaks and unexpected state changes
   */
  const clearSavedTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [])

  /**
   * Handle the simple boolean prop approach - only if saving prop is defined
   * When saving=true, show saving indicator
   * When saving=false after was true, show saved indicator with timeout
   */
  useEffect(() => {
    if (saving === undefined) return
    // Only handle prop-based control if saving is explicitly defined
    isUsingPropControl.current = true
    if (saving) {
      setState(SAVED_STATE.SAVING)
      clearSavedTimeout()
    } else if (state === SAVED_STATE.SAVING) {
      setState(SAVED_STATE.SAVED)
      clearSavedTimeout()
      timeoutRef.current = setTimeout(() => {
        setState(SAVED_STATE.INITIAL)
      }, timeout)
    }
  }, [saving, state, clearSavedTimeout, timeout])

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => clearSavedTimeout()
  }, [clearSavedTimeout])

  /**
   * Callback method that transitions to SAVING state
   * Only works when not using prop control
   */
  const savingStart = useCallback(() => {
    if (isUsingPropControl.current) return console.warn('[SavedStateIndicator] savingStart() called while using prop control - ignoring')
    clearSavedTimeout()
    setState(SAVED_STATE.SAVING)
  }, [clearSavedTimeout])

  /**
   * Callback method that transitions to SAVED state with automatic timeout
   * Only works when not using prop control
   */
  const savingDone = useCallback(() => {
    if (isUsingPropControl.current) return console.warn('[SavedStateIndicator] savingDone() called while using prop control - ignoring')
    setState(SAVED_STATE.SAVED)
    clearSavedTimeout()
    timeoutRef.current = setTimeout(() => {
      setState(SAVED_STATE.INITIAL)
    }, timeout)
  }, [clearSavedTimeout, timeout])

  /**
   * Callback method that transitions to ERROR state with automatic timeout
   * Only works when not using prop control
   */
  const savingFail = useCallback(() => {
    if (isUsingPropControl.current) return console.warn('[SavedStateIndicator] savingFail() called while using prop control - ignoring')
    setState(SAVED_STATE.ERROR)
    clearSavedTimeout()
    timeoutRef.current = setTimeout(() => {
      setState(SAVED_STATE.INITIAL)
    }, timeout)
  }, [clearSavedTimeout, timeout])

  /**
   * Expose methods to parent components via ref
   */
  useImperativeHandle(ref, () => ({
    savingStart,
    savingDone,
    savingFail,
  }))

  if (state === SAVED_STATE.INITIAL) {
    return null
  }

  const stateClasses = {
    [SAVED_STATE.SAVING]: 'text-green-500',
    [SAVED_STATE.SAVED]: 'text-green-500',
    [SAVED_STATE.ERROR]: 'text-red-500',
  }

  return (
    <div
      className={[
        'bg-black/40 rounded-sm px-1 text-xs',
        stateClasses[state],
        className,
      ].join(' ')}
    >
      {state === SAVED_STATE.SAVING && (
        <>
          <FontAwesomeIcon icon={faSpinner} size="sm" className="animate-spin" />
          <span className="ml-1">{savingLabel}</span>
        </>
      )}
      {state === SAVED_STATE.SAVED && (
        <>
          <FontAwesomeIcon icon={faCheckCircle} size="sm" />
          <span className="ml-1">{successLabel}</span>
        </>
      )}
      {state === SAVED_STATE.ERROR && (
        <>
          <FontAwesomeIcon icon={faExclamationCircle} size="sm" />
          <span className="ml-1">{failedLabel}</span>
        </>
      )}
    </div>
  )
})

SavedStateIndicator.propTypes = {
  saving: PropTypes.bool,
  className: PropTypes.string,
  failedLabel: PropTypes.string,
  savingLabel: PropTypes.string,
  successLabel: PropTypes.string,
  timeout: PropTypes.number,
}

export default SavedStateIndicator

// Example usage with hook:
//
// const { indicator, savingStart, savingDone, savingFail } = useSavedState({
//   timeout: 2000,
//   className: 'bottom-2 right-2'
// })
//
// async function handleSave() {
//   savingStart()
//   try {
//     await saveData()
//     savingDone()
//   } catch (error) {
//     savingFail(error.message)
//   }
// }
//
// return (
//   <div className="relative">
//     <button onClick={handleSave}>Save</button>
//     {indicator}
//   </div>
// )

/**
 * Custom hook for using SavedStateIndicator with a simpler API
 *
 * @param {Object} options - Configuration options
 * @param {number} options.timeout - How long to show the success/error state in ms
 * @param {string} options.className - Additional CSS classes for positioning
 * @param {string} options.savingLabel - Custom text for saving state
 * @param {string} options.successLabel - Custom text for success state
 * @param {string} options.failedLabel - Custom text for failed state
 * @returns {Object} Object containing indicator element and control methods
 */
export function useSavedState (options = {}) {
  const {
    timeout = 2000,
    className = '',
    savingLabel,
    successLabel,
    failedLabel,
  } = options
  const indicatorRef = useRef(null)

  // This state violates the single-source-of-truth principle.
  // We assume this hook excludes using the props or ref directly.
  const [isSaving, setIsSaving] = useState(false)

  const savingStart = useCallback(() => {
    indicatorRef.current?.savingStart?.()
    setIsSaving(true)
  }, [])

  const savingDone = useCallback(() => {
    indicatorRef.current?.savingDone?.()
    setIsSaving(false)
  }, [])

  const savingFail = useCallback(() => {
    indicatorRef.current?.savingFail?.()
    setIsSaving(false)
  }, [])

  return {
    savingStart,
    savingDone,
    savingFail,
    isSaving,
    indicator: (
      <SavedStateIndicator
        ref={indicatorRef}
        timeout={timeout}
        className={className}
        savingLabel={savingLabel}
        successLabel={successLabel}
        failedLabel={failedLabel}
      />
    ),
  }
}
